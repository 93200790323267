import { render, staticRenderFns } from "./DialogOrderEdit.vue?vue&type=template&id=2ffd574d&scoped=true"
import script from "./DialogOrderEdit.vue?vue&type=script&lang=js"
export * from "./DialogOrderEdit.vue?vue&type=script&lang=js"
import style0 from "./DialogOrderEdit.vue?vue&type=style&index=0&id=2ffd574d&prod&lang=stylus"
import style1 from "./DialogOrderEdit.vue?vue&type=style&index=1&id=2ffd574d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffd574d",
  null
  
)

export default component.exports