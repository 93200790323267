<template>
    <div class="container address-widget">
        <address-input
            v-if="showAddress"
            :data="addressData"
            :center="mapCenter"
            :zoom="mapZoom"
            :access-position="true"
            :self-position="false"
            :header="header"
            subtitle=""
            @input="onAddressInput"
            @select="onAddressSelect"
            @close="showAddress = false"
        />

        <v-row>
            <v-col cols="12">
                <span class="d-block mb-1" :style="{
                    color: isFoundCoordinates ? 'green' : 'red',
                }">{{ isFoundCoordinates ? statusPositive : statusNegative }}</span>

                <v-tooltip v-if="tooltipText" bottom :disabled="readOnly" open-delay="800">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="tooltip-text" v-bind="attrs" v-on="on">

                            <div id="address-map" :style="{ height: height + 'px' }" @click="onMapClick">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="map-pin" role="img" viewBox="0 0 384 512">
                                    <path
                                        fill="#7a2a9a"
                                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                    />
                                </svg>
                            </div>

                        </span>
                    </template>
                    <span>{{ tooltipText }}</span>
                </v-tooltip>


                <div v-else id="address-map" :style="{ height: height + 'px' }" @click="onMapClick">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="map-pin" role="img" viewBox="0 0 384 512">
                        <path
                            fill="#7a2a9a"
                            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                        />
                    </svg>
                </div>

            </v-col>
        </v-row>
    </div>
</template>


<script>
    import AddressInput from './Main.vue'
    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'
    const config = require('../../config')

    export default {
        name: 'AddressWidget',

        components: { AddressInput },

        props: {
            readOnly: {
                type: Boolean,
                required: true,
            },

            header: {
                type: String,
                required: true,
            },

            tooltipText: {
                type: String,
                default: null,
            },

            statusPositive: {
                type: String,
                required: true,
            },

            statusNegative: {
                type: String,
                required: true,
            },

            defaultLatitude: {
                type: Number,
                required: true,
            },

            defaultLongitude: {
                type: Number,
                required: true,
            },

            address: {
                type: String,
                required: true,
            },

            latitude: {
                type: Number,
                required: true,
            },

            longitude: {
                type: Number,
                required: true,
            },

            height: {
                type: Number,
                default: 100,
            },
        },

        emits: ['update', 'input'],

        computed: {
            inputCoordinates() {
                return [this.latitude, this.longitude]
            }
        },

        watch: {
            inputCoordinates(newValue, oldValue) {
                // console.log('address data change', newValue, oldValue)

                if (newValue[0] && newValue[0] !== oldValue[0] &&
                    newValue[1] && newValue[1] !== oldValue[1]) {
                    this.isFoundCoordinates = true
                    this.addressData.address = this.address
                    this.addressData.position.lat = newValue[0]
                    this.addressData.position.lng = newValue[1]
                    this.updateAddressData()
                }
            },
        },

        data() {
            return {
                isFoundCoordinates: this.latitude && this.longitude,
                showAddress: false,
                addressData: {
                    address: this.address,
                    position: {
                        lat: this.latitude || this.defaultLatitude,
                        lng: this.longitude || this.defaultLongitude,
                    },
                },
                mapZoom: 15,
                mapCenter: [this.defaultLatitude, this.defaultLongitude],
            }
        },

        mounted() {
            // console.log('address widget mounted', this.addressData)
            this.mapZoom = this.isFoundCoordinates ? 17 : 14

            setTimeout(() => {
                this.addressMap = new L.map('address-map', {
                    center: this.addressData.position,
                    zoom: this.mapZoom,
                    dragging: false,
                    scrollWheelZoom: false,
                    zoomControl: false,
                    doubleClickZoom: false,
                    attributionControl: false,
                })

                L.tileLayer(config.map.url, {
                    attributionControl: false,
                    maxZoom: 20,
                    minZoom: 13,
                    retina: '@2x',
                    detectRetina: true,
                }).addTo(this.addressMap)

                this.addressMap.invalidateSize(false)
                this.addressMap.setView(this.addressData.position, this.mapZoom)
            })
        },

        created() {
            // console.log('address widget created', this.addressData)
        },

        methods: {
            onMapClick() {
                this.showAddress = !this.readOnly
            },

            mapUpdate() {
                setTimeout(() => {
                    if (this.addressMap) this.addressMap.invalidateSize(false)
                    this.addressMap.setView(this.addressData.position, this.mapZoom)
                })
            },

            updateAddressData() {
                console.log('[Address Widget] this.addressData', this.addressData)
                this.$emit('update', {
                    isFoundCoordinates: this.isFoundCoordinates,
                    address: this.addressData.address,
                    latitude: this.addressData.position.lat,
                    longitude: this.addressData.position.lng,
                })

                this.mapZoom = this.isFoundCoordinates ? 17 : 14

                console.log('[Address Widget] before map update')
                this.mapUpdate()
            },

            onAddressSelect(data) {
                if (data?.position) {
                    this.isFoundCoordinates = true
                    this.addressData.address = data.address
                    this.addressData.position.lat = data.position.lat
                    this.addressData.position.lng = data.position.lng
                } else {
                    this.isFoundCoordinates = false
                    this.addressData.position.lat = this.defaultLatitude
                    this.addressData.position.lng = this.defaultLongitude
                }

                this.updateAddressData()
            },

            onAddressInput(value) {
                this.$emit('input', value)
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .map-pin
        width 30px !important
        height 30px !important
        margin auto
        position absolute
        top 0
        left 0
        bottom 30px
        right 0
        z-index 401

    .address-widget
        padding 0

    #address-map
        cursor pointer
        box-shadow 0px 0px 2px #aaa, 1px 1px 2px #aaa

    #address-map:hover
        box-shadow 0px 0px 2px grey, 1px 1px 4px grey
</style>
