<template>
    <transition name="modal">
        <div class="modal-mask" :style="{ 'z-index': zIndex }">
            <div class="modal-wrapper" @mousedown.self="onClose">
                <div class="modal-container" :class="$store.getters.getOS == 'iOS' ? 'ios' : 'other'" :style="{width: width}">

                    <div class="modal-header">
                        <slot name="header"> header </slot>

                        <div class="modal-close-button">
                            <button type="button" class="close btn-close" data-dismiss="modal" aria-label="Close" style="position: relative; z-index: 999999" @click="onClose">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18" fill="none">
                                    <path
                                        d="M15.0694 17.4972C15.7398 18.1676 16.8268 18.1676 17.4972 17.4972C18.1676 16.8268 18.1676 15.7398 17.4972 15.0694L11.4278 8.99999L17.4972 2.93057C18.1676 2.26016 18.1676 1.17321 17.4972 0.502806C16.8268 -0.167603 15.7398 -0.167601 15.0694 0.502808L8.99999 6.57222L2.9306 0.502837C2.26019 -0.167573 1.17324 -0.167572 0.502832 0.502838C-0.167577 1.17325 -0.167576 2.2602 0.502834 2.93061L6.57222 8.99999L0.502807 15.0694C-0.167602 15.7398 -0.167602 16.8268 0.502807 17.4972C1.17322 18.1676 2.26017 18.1676 2.93057 17.4972L8.99998 11.4278L15.0694 17.4972Z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <slot name="body"> body </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer"> footer </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'WindowModal',
        props: {
            width: {
                type: String,
                required: true,
            },
            height: {
                type: String,
                required: true,
            },
            zIndex: {
                type: Number,
                default: 9998,
            },
        },
        emits: ['close-modal', 'enter-up'],
        mounted() {
            this.disableScroll()
            document.addEventListener('keyup', this.onEscEvent)
            document.addEventListener('keydown', this.onEnterEvent)
        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.onEscEvent)
            document.removeEventListener('keydown', this.onEnterEvent)
            this.enableScroll()
        },
        methods: {
            preventDefault(e) {
                e.preventDefault()
            },
            disableScroll() {
                const body = document.body
                body.style.height = '100vh'
                body.style.overflowY = 'hidden'

                document.body.addEventListener('touchmove', this.preventDefault, { passive: false })
            },
            enableScroll() {
                const body = document.body
                body.style.height = ''
                body.style.overflowY = ''

                document.body.removeEventListener('touchmove', this.preventDefault, { passive: false })
            },
            onClose() {
                this.$emit('close-modal')
                this.enableScroll()
            },
            onEnterEvent(e) {
                if (e.key === 'Enter') {
                    this.$emit('enter-up')
                }
            },
            onEscEvent(e) {
                if (e.key === 'Escape') {
                    this.$emit('close-modal')
                }
            },
        },
    }
</script>

<style>
    @media screen and (max-width: 799px) {
        .modal-container.ios {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: -70px;
            padding-bottom: 70px;
            transform: translateY(70px);
        }
        .modal-container.other {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0px;
            top: 0;
            padding-top: 0px;
        }
    }

    a.text:active,
    a.text:hover,
    a.text {
        text-decoration: none;
        color: #7a2a9a;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(12, 4, 63, 0.6);
        backdrop-filter: blur(5px);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        z-index: 9999;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .modal-container {
        margin: 0px auto;
        padding: 0;
        background-color: #fff;
        box-shadow: 0px 0px 50px rgba(9, 10, 19, 0.08);
        border-radius: 15px;
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header {
        position: relative;
        padding: 20px;
        margin-left: 10px;
    }

    .modal-footer {
        border-top: 0 none !important;
        justify-content: center !important;
    }

    .btn-close {
        /* background-color: #f9f9f9; */
        color: #242424;
        width: 40px;
        height: 40px;
        padding: 0;
        box-sizing: initial;
        /* border: solid 1px #f3f3f3; */
        border-radius: 50%;
        cursor: pointer;
    }
    .btn-close svg {
        padding: 12px;
    }
    .btn-close svg path {
        fill: #8c8c8e;
    }
    .btn-close:hover svg path {
        fill: #7c7c7e;
    }

    .btn-close:hover {
        background-color: #f0f0f0;
        color: #242424;
    }

    .title-text {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        color: #0c043f;
        margin-left: auto;
        margin-right: auto;
    }

    .body-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #0c043f;
        opacity: 0.7;
    }

    .modal-close-button {
        position: absolute;
        right: 20px;
        top: 10px;
    }

    @media screen and (max-width: 576px) {
        .modal-container {
            border-radius: 0px;
            width: 100%;
            height: 100%;
            padding: 0 0 0 0;
        }
        .modal-footer {
            position: absolute;
            bottom: 100px;
            left: 0;
            right: 0;
        }
        .modal-header {
            padding-top: 20px;
        }
        .modal-close-button {
            padding: 20px;
        }
        .title-text {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 992px) {
        .modal-container {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
        .modal-footer {
            position: absolute;
            bottom: 100px;
            left: 0;
            right: 0;
        }
        .modal-header {
            padding-top: 20px;
        }
        .modal-close-button {
            padding-right: 20px;
            padding-top: 10px;
        }
    }

    .scroll-lock {
        height: 100vh;
        overflow-y: hidden;
    }
</style>
