<template>
    <div>
        <v-dialog v-model="orderDialog" max-width="800" max-height="640" :persistent="addressValid()" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable @click:outside="onClickClose" @keydown.esc="onClickClose">
            <v-card :loading="loadingOrderDialog">
                <v-card-title class="grey lighten-2">
                    {{ header }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onClickClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-0 ma-0">

                    <v-stepper v-model="orderStep" non-linear tile vertical elevation="0" light @change="onChangeStepper">
                        <v-stepper-step :complete="true" step="1" :editable="true">
                            Адрес точки, откуда забрать
                            <br />
                            <span class="step-info"
                                ><b>{{ orderPointAddress }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left pb-0">
                                    <span>Выберите точку, откуда нужно забрать заказ:</span>
                                </v-col>

                                <v-col cols="7" class="text-center">
                                    <v-autocomplete :items="points" :value="selectedPointId" label="Начните вводить или выберите из списка" class="ma-0 pa-0 input-point" item-value="id" item-text="name" persistent-hint return-object single-line hide-details solo @change="onChangePoint"></v-autocomplete>
                                </v-col>

                                <v-col cols="5" class="text-center d-flex align-center justify-center">
                                    <v-btn color="primary" class="mx-1" :disabled="!point.address" @click="orderStep = 2">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step :complete="addressValid()" step="2" :editable="addressValid()">
                            Адрес гостя, куда доставить
                            <br />
                            <span class="step-info"
                                ><b>{{ getAddress() }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="2">

                            <v-row class="stepper-content">

                                <v-col cols="12" sm="6">
                                    <address-widget
                                        header="Укажите адрес или место доставки"
                                        status-positive="✓ Для изменения места нажмите на карту"
                                        status-negative="❗️Нажмите на карту и укажите место доставки"
                                        :height="190"
                                        :read-only="false"
                                        :default-latitude="$store.state.user.city.latitude"
                                        :default-longitude="$store.state.user.city.longitude"
                                        :address="order.address"
                                        :latitude="order.latitude"
                                        :longitude="order.longitude"
                                        @update="onAddressWidgetUpdate"
                                    ></address-widget>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-row>
                                        <v-col cols="12" class="text-center">
                                            <v-text-field :disabled="!isFoundCoordinates" v-model="order.address" :label="addressWrong() ? 'Впишите сюда адрес' : 'Адрес'" hide-details :error="addressWrong()"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="pb-0">
                                            <span :class="{'grey--text': !isFoundCoordinates}">Укажите дополнительную информацию по адресу:</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!isFoundCoordinates" v-model="additionalAddress.flat" label="Квартира" hide-details dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!isFoundCoordinates" v-model="additionalAddress.entrance" label="Подъезд" hide-details dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!isFoundCoordinates" v-model="additionalAddress.floor" label="Этаж" hide-details dense> </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field :disabled="!isFoundCoordinates" v-model="additionalAddress.other" label="Другое" hide-details dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mt-2 mx-1" @click="orderStep = 1">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" :disabled="!addressValid()" class="mt-2 mx-1" @click="orderStep = 3">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="this.order.isNearest !== undefined" step="3" :editable="copyFlag || this.order.isNearest !== undefined">
                            Время доставки
                            <br />
                            <span v-if="isTimeReady" class="step-info" v-html="getTimes()"></span>
                        </v-stepper-step>

                        <v-stepper-content step="3">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-center">
                                    <div class="mb-4">
                                        <span>Выберите тип заказа:</span><br />
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="dialogTypeNearest = true">{{ getOrderModeString(true) }}</v-btn>
                                        <v-btn
                                            outlined
                                            text
                                            class="mx-1 mt-1 keyboard"
                                            @click="
                                                dialogTypePreorder = true
                                                preorderStep = 1
                                            "
                                        >
                                            {{ getOrderModeString(false) }}
                                        </v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 2">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="this.order.isNearest === undefined" @click="orderStep = 4">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step :complete="order.customerString !== undefined" step="4" :editable="copyFlag || order.customerString !== undefined">
                            Имя и телефон гостя
                            <br />
                            <span class="step-info"
                                ><b>{{ order.customerString ?? '' }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="4">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left ml-3">
                                    <span>Укажите имя и телефон гостя, например, "Анна +79023456780":</span>
                                    <v-text-field autofocus style="width: 400px" v-model="order.customerString" flat hide-details> </v-text-field>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 3">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="order.customerString === undefined" @click="orderStep = 5">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="orderPayCostValid() === true" step="5" :editable="copyFlag || orderPayCostValid() === true">
                            Оплата
                            <br />
                            <span class="step-info" v-html="orderPayCost()"></span>
                        </v-stepper-step>

                        <v-stepper-content step="5">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-center">
                                    <div class="mb-4">
                                        <span>Выберите тип оплаты:</span><br />
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('✅ Оплачено')"> ✅ Оплачено </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('🆗 Без сдачи')"> 🆗 Без сдачи </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('🔸 Сдача')"> 🔸 Сдача </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('↔️ Перевод')"> ↔️ Перевод </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('📸 QR/ссылка')"> 📸 QR/ссылка </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('💳 Безнал')"> 💳 Безнал </v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 4">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="!orderPayCostValid()" @click="orderStep = 6">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="orderStepLast" step="6" :editable="copyFlag || orderPayCostValid() === true">
                            Комментарий
                            <br />
                            <span :class="orderCommentEmpty() ? 'step-empty' : 'step-info'" v-html="orderCommentEmpty() ? 'не задан' : orderComment"></span>
                        </v-stepper-step>

                        <v-stepper-content step="6">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left">
                                    <div class="mb-4">
                                        <v-textarea autofocus rows="2" v-model="orderComment" label="Введите комментарий (примечание от гостя или заведения)" hide-details> </v-textarea>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 5">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" @click="onClickOrderCommentSelect">
                                        {{ orderComment?.length ? 'Готово' : 'Пропустить' }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper>

                    <v-row no-gutters class="justify-end pb-2 px-6 blue-grey--text font-weight-regular">
                        <span v-if="orderValid()">* Проверьте данные заказа и нажмите <b>"Создать заказ"</b></span>
                        <span v-else>* Заполните обязательные поля для нового заказа</span>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="mx-2">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="5" class="d-flex align-center">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    Стоимость доставки:
                                    <span v-html="orderDeliveryFee()" :class="addressValid() ? 'step-info' : ''"></span>
                                </v-col>
                                <v-col cols="12">
                                    Общее время доставки:
                                    <span v-html="orderDeliveryTime()" :class="this.order.isNearest !== undefined ? 'step-info' : ''"></span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" sm="6" md="7">
                            <v-row no-gutters>

                                <v-col cols="12" md="6" class="d-flex justify-center">
                                    <v-tooltip v-if="addressValid()" top open-delay="800">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-btn class="mx-2 my-1" outlined color="primary" @click="onClickRoute" hide-details> 🗺 Маршрут {{ order.distance ? distanceString(order.distance) : '' }} </v-btn>
                                            </span>
                                        </template>
                                        <span>Посмотреть маршрут следования курьера</span>
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="12" md="6" class="d-flex justify-center">
                                    <v-btn class="mx-2 my-1" color="success" :disabled="loadingOrderDialog || !orderValid() || !isTimeReady" @click="onClickCreate">Создать заказ</v-btn>
                                </v-col>

                            </v-row>
                        </v-col>

                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTypePreorder" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ getOrderModeString(false) }}
                    {{ preorderStep == 2 ? 'на ' + getDayStr(preorderDay) : '' }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogTypePreorder = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="preorderStep == 1" class="py-4">
                    <p><b>Шаг 1</b>. Выберите день, <b>когда нужно доставить</b>:</p>
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickPreorderDay(0)">{{ getDayStr(0) }} </v-btn>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="dialogTypePreorder = false">⬅️ Назад </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-for="i in Math.round((1 + preorderDays.length) / 3)" :key="i">
                        <v-col v-for="j in 3" :key="j" cols="4" class="pa-1">
                            <v-btn block outlined text :disabled="(i - 1) * 3 + j > preorderDays.length" class="keyboard" @click="onClickPreorderDay((i - 1) * 3 + j)"> {{ getDayStr((i - 1) * 3 + j) }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-card-text v-if="preorderStep == 2" class="py-4">
                    <p><b>Шаг 2</b>. Укажите время, <b>во сколько будет готов</b>:</p>
                    <v-row>
                        <v-col cols="3" class="pa-3">
                            <v-text-field autofocus v-model="preorderTimeReady" type="time" dense hide-details @keydown.enter="preorderNext"></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pa-3 mt-1"> </v-col>
                        <v-col cols="12" class="d-flex justify-end">
                            <v-btn text color="primary" class="mx-1" @click="preorderStep -= 1"> <v-icon>mdi-chevron-left</v-icon> Назад </v-btn>
                            <v-btn color="primary" :disabled="!preorderTimeReady" class="mx-1" @click="preorderNext"> Далее <v-icon>mdi-chevron-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-if="preorderStep == 3" class="py-4">
                    <v-row>
                        <v-col cols="12">
                            <p><b>Шаг 3</b>. Выберите <b>диапазон</b> или <b>точное время</b> доставки:</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header v-slot="{ open }" @click="preorderTimeDeliveryMode = 1">
                                    <v-row no-gutters>
                                        <v-col cols="8"> 🕓 Точное время </v-col>

                                        <v-col cols="4" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                                <v-row v-if="!open" no-gutters style="width: 100%">
                                                    <v-col cols="12">
                                                        {{ preorderTimeDeliveryMode == 1 ? preorderTimeDeliveryFrom || '' : '' }}
                                                    </v-col>
                                                </v-row>
                                            </v-fade-transition>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <span v-bind="attrs" v-on="on">Укажите время, <b>к которому нужно доставить</b></span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6" sm="3">
                                                            <v-text-field autofocus v-model="preorderTimeDeliveryFrom" type="time" dense hide-details @keydown.enter="onClickPreorderTimeDelivery"> </v-text-field>
                                                        </v-col>
                                                        <v-col cols="6" sm="9" class="mt-1 d-flex justify-center">
                                                            <v-btn color="primary" class="mx-1" :disabled="preorderTimeDeliveryDisable()" @click="onClickPreorderTimeDelivery">Далее <v-icon> mdi-chevron-right</v-icon> </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header v-slot="{ open }" @click="preorderTimeDeliveryMode = 2">
                                    <v-row no-gutters>
                                        <v-col cols="8"> ⏳ Диапазон </v-col>

                                        <v-col cols="4" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                                <v-row v-if="!open" no-gutters style="width: 100%">
                                                    <v-col cols="12">
                                                        {{ preorderTimeDeliveryMode == 2 ? (preorderTimeDeliveryFrom && preorderTimeDeliveryTo ? preorderTimeDeliveryFrom + ' - ' + preorderTimeDeliveryTo : '') : '' }}
                                                    </v-col>
                                                </v-row>
                                            </v-fade-transition>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <span v-bind="attrs" v-on="on">Укажите диапазон времени, <b>в который нужно доставить</b></span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            от:
                                                            <v-text-field autofocus v-model="preorderTimeDeliveryFrom" type="time" dense hide-details></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            до:
                                                            <v-text-field v-model="preorderTimeDeliveryTo" type="time" dense hide-details @keydown.enter="onClickPreorderTimeDelivery"> </v-text-field>
                                                        </v-col>
                                                        <v-col cols="6" class="mt-5 d-flex justify-center">
                                                            <v-btn color="primary" class="mx-1" :disabled="preorderTimeDeliveryDisable()" @click="onClickPreorderTimeDelivery">Далее <v-icon> mdi-chevron-right</v-icon> </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn text color="primary" class="mx-1" @click="preorderStep -= 1"> <v-icon>mdi-chevron-left</v-icon> Назад </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPayCost" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ order.pay }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogPayCost = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-6">
                    <v-row>
                        <v-col cols="12" class="pa-0 mx-2">
                            <p>Укажите <b>сумму заказа</b> (не менее 100 ₽):</p>
                        </v-col>
                        <v-col cols="6" class="pa-0 mx-2">
                            <v-text-field class="input-cost" append-icon="mdi-currency-rub" type="number" v-model.number="order.payCost" autofocus dense hide-details @keydown.enter="orderPayCostNext"> </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="pa-3 mt-1 d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="mx-1" :disabled="!orderPayCostValid()" @click="orderPayCostNext">Выбрать <v-icon>mdi-chevron-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTypeNearest" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ getOrderModeString(true) }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogTypeNearest = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-4">
                    <p>Выберите <b>через сколько будет готов</b>:</p>
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(0)">✅ Уже готов </v-btn>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="dialogTypeNearest = false">⬅️ Назад </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(5)">5 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(10)">10 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(15)">15 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(20)">20 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(25)">25 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(30)">30 мин</v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(40)">40 мин</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(50)">50 мин</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(60)">1 час</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(90)">1 час 30 мин </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptClose" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Отмена создания заказа
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="py-4">
                    Вы действительно хотите <b>отменить создание заказа</b>?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptClose = false">Нет</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="promptCloseYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Notify from '../../mixins/Notify.js'
    import Orders from '../../mixins/Orders.js'
    import addressWidget from '../address_input/Widget.vue'

    export default {
        name: 'dialog-order-create',

        components: {
            addressWidget,
        },

        mixins: [DateTime, Notify, Orders],

        props: {
            data: {
                type: Object,
            },
            settings: {
                type: Object,
                required: true,
            },
            courierPrice: {
                type: Number,
                default: 0,
            },
            points: {
                type: Array,
                default: null,
            },
            userOperator: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: '',
            },
        },

        emits: ['close', 'create'],

        watch: {
            orderDialog: function (newValue, oldValue) {
                if (!newValue) {
                    this.$emit('close')
                }
            },
        },

        computed: {
            orderDeliveryFeeClass: function () {
                return {
                    'step-info': this.addressValid() ? true : false,
                }
            },

            orderDeliveryTimeClass: function () {
                return {
                    'step-info': this.order.isNearest !== undefined ? true : false,
                }
            },
        },

        data() {
            return {
                orderStep: 0,
                preorderStep: 0,
                order: {
                    commentPoint: '',
                    commentOperator: '',
                    address: '',
                    latitude: 0,
                    longitude: 0,
                },
                isFoundCoordinates: false,
                config,
                timerPriceUpdate: null,
                orderDialog: false,
                additionalAddress: {},
                promptClose: false,
                dialogTypeNearest: false,
                dialogTypePreorder: false,
                preorderDay: 0,
                preorderDays: [],
                preorderTimeReady: '',
                preorderTimeDelivery: '',
                preorderTimeDeliveryFrom: '',
                preorderTimeDeliveryTo: '',
                preorderTimeDeliveryMode: 0,
                dialogPayCost: false,
                addressEmptySelect: false,
                orderCommentSelect: false,
                orderComment: null,
                orderStepLast: false,
                orderPointAddress: '',
                pointPrice: 0,
                point: {},
                timeTake: 1800, // 30 мин
                timeDelivery: 1800, // 30 мин
                additionalComment: null,
                promptCloseFlag: false,
                selectedPointId: null,
                loadingOrderDialog: false,
                isTimeReady: false,
                copyFlag: this.data ? true : false,
                holidays: [
                    {
                        name: 'День Святого Валентина',
                        icon: '💕',
                        date: '14 фев.',
                        daysCount: 1,
                    },
                    {
                        name: 'День Защитника Отечества',
                        icon: '🛡',
                        date: '23 фев.',
                        daysCount: 1,
                    },
                    {
                        name: '8 Марта',
                        icon: '🌷',
                        date: '8 мар.',
                        daysCount: 1,
                    },
                    {
                        name: '1 Мая - праздник Весны и Труда',
                        icon: '🎈',
                        date: '1 мая',
                        daysCount: 1,
                    },
                    {
                        name: 'День защиты детей',
                        icon: '👶',
                        date: '1 июн.',
                        daysCount: 1,
                    },
                    {
                        name: 'День России',
                        icon: '🇷🇺',
                        date: '12 июн.',
                        daysCount: 1,
                    },
                    {
                        name: '1 Сентября',
                        icon: '📚',
                        date: '1 сент.',
                        daysCount: 1,
                    },
                    {
                        name: 'Новый Год',
                        icon: '🎅🏼',
                        date: '31 дек.',
                        daysCount: 7,
                    },
                ],
            }
        },

        created() {
            if (this.points && this.points.length) {
                this.preorderDaysGenerate()
                console.log('this.preorderDays', this.preorderDays)
                this.orderDialog = true
            } else {
                this.notifyToast('Невозможно создать заказ: нет ни одной точки', { color: 'error', timeout: 15000 })
                this.$emit('close')
            }

            setTimeout(() => {
                this.orderStep = 1

                if (this.data) {
                    console.log('copy order', this.data)

                    this.order = {
                        pointId: this.data.pointId,
                        address: this.data.address,
                        createdAt: null,
                        dateTake: null,
                        dateComplete: null,
                        isNearest: this.data.isNearest,
                        latitude: this.data.latitude,
                        longitude: this.data.longitude,
                        feePoint: this.data.feePoint,
                        feeCourier: this.data.feeCourier,
                        customerString: this.data.customerString,
                        pay: this.data.pay,
                        payCost: this.data.payCost,
                        distance: this.data.distance,
                    }

                    this.pointSelect(this.order.pointId)

                    if (this.order.latitude && this.order.longitude) {
                        this.isFoundCoordinates = true
                        this.priceUpdate(this.data.latitude, this.data.longitude)
                    }

                    if (this.data.isNearest) {
                        const regexp = /^готовность: (\d+ мин|уже готов)[,]? ?/

                        let time = 0

                        if (this.data.commentPoint) {
                            this.orderComment = this.data.commentPoint?.replace(regexp, '') ?? null
                            const timeMatch = this.data.commentPoint.match(regexp)
                            if (timeMatch && timeMatch[1]) {
                                time = parseInt(timeMatch[1], 10)
                                console.log('time point', time)
                                if (!time) {
                                    time = 0
                                }
                            }
                        }

                        if (this.data.commentOperator) {
                            this.orderComment = this.data.commentOperator?.replace(regexp, '') ?? null

                            const timeMatch = this.data.commentOperator.match(regexp)
                            if (timeMatch && timeMatch[1]) {
                                time = parseInt(timeMatch[1], 10)
                                console.log('time operator', time)
                                if (!time) {
                                    time = 0
                                }
                            }
                        }

                        this.nearestTimeUpdate(time)
                    } else {
                        this.orderComment = this.userOperator ? this.data.commentOperator : this.data.commentPoint
                        this.order.dateReady = this.data.dateReady
                        this.order.dateDelivery = this.data.dateDelivery

                        this.preorderTimeReady = moment(this.order.dateReady).format('HH:mm')
                        this.preorderTimeDeliveryFrom = moment(this.order.dateDelivery).format('HH:mm')
                        this.preorderTimeDeliveryTo = moment(this.order.dateDelivery).format('HH:mm')

                        this.isTimeReady = true
                    }

                    this.onClickOrderCommentSelect()
                } else {
                    if (this.points.length == 1) {
                        this.pointSelect(this.points[0].id)
                        this.orderStep += 1
                    }
                    this.isTimeReady = true
                }
            })
            this.disableAppScroll()
        },

        beforeDestroy() {
            this.enableAppScroll()
        },

        methods: {
            
            onInputComment(value) {
                console.log('value', value)
                this.orderComment = value
            },
            nearestTimeUpdate(time) {
                const interval = setInterval(() => {
                    if (!this.loadingOrderDialog) {
                        clearInterval(interval)
                        this.onClickNearestTime(time)
                        this.isTimeReady = true
                    }
                }, 1000)
            },

            disableAppScroll() {
                setTimeout(() => {
                    this.scrollY = window.scrollY
                    const app = document.getElementById('app')
                    if (app) {
                        app.style.overflow = 'hidden'
                    }
                }, 500)
            },

            enableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = ''
                }
                window.scrollTo({ top: this.scrollY })
            },

            onClickRoute() {
                this.$store.dispatch('open_route', { point: this.point, order: this.order })
            },

            orderDeliveryFee() {
                return this.pointPrice ? `<b>${this.pointPrice} ₽</b>` : '--'
            },

            orderDeliveryTime() {
                let dateBegin
                if (this.order.isNearest) {
                    dateBegin = this.order.createdAt
                } else {
                    dateBegin = this.order.dateReady
                }
                if (!dateBegin) dateBegin = new Date()
                console.log('orderDeliveryTime', dateBegin, this.order.dateDelivery)
                return this.order.dateDelivery ? `<b>${this.timeDiff(dateBegin, this.order.dateDelivery, false)}</b>` : '--'
            },

            onChangeStepper(event) {
                // console.log('onChangeStepper', event, this.orderStep)
            },

            onChangePoint(point) {
                this.pointSelect(point.id)
            },

            pointSelect(pointId) {
                this.selectedPointId = pointId
                this.point = this.points.find(point => point.id === pointId)
                console.log('point', this.point)
                console.log('order', this.order)

                if (!this.point) {
                    this.notifyToast('Не удалось определить точку', { color: 'error', timeout: 15000 })
                    this.point = this.points[0]
                }
                if (this.addressValid()) {
                    this.priceUpdate(this.order.latitude, this.order.longitude)
                }

                this.order.pointId = this.point.id
                this.orderPointAddress = this.point.address
            },

            onClickOrderCommentSelect() {
                this.orderStep = 7
                this.orderStepLast = true
                this.orderCommentSelect = true
            },

            orderCommentEmpty() {
                return (!this.orderComment || this.orderComment == '') && this.orderCommentSelect
            },

            orderPayCostNext() {
                if (this.orderPayCostValid()) {
                    this.dialogPayCost = false
                }
            },

            orderValid() {
                return this.orderStepLast && this.orderPayCostValid()
            },

            orderPayCostValid() {
                return this.order.payCost && this.order.payCost >= 100 && this.order.payCost < 100000
            },

            orderPayCost(c) {
                return this.orderPayCostValid() ? `<b>${this.order.pay}</b>, сумма заказа: <b>${ +this.order.payCost } ₽</b>` : ''
            },

            getOrderCommentString() {
                return this.orderComment?.length ? `<b>${this.orderComment.length > 50 ? this.orderComment.substring(0, 50) + '...' : this.orderComment}</b>` : ''
            },

            onClickOrderPay(pay) {
                this.order.pay = pay
                this.dialogPayCost = true
            },

            preorderNext() {
                if (this.preorderTimeReady != '') {
                    this.preorderStep += 1
                }
            },

            preorderDaysGenerate() {
                this.preorderDays = []

                const daysMax = 14
                const daysFixed = 6
                let holidaysCount = 0

                const holidayString = (holiday, dateCurrent) => {
                    return `${holiday.icon} ${dateCurrent.format('D MMM')}`
                }

                for (let i = 0; i < daysMax; i += 1) {
                    const dateCurrent = moment()
                        .set({
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                        })
                        .add(i + 1, 'days')

                    let text = dateCurrent.format('D MMM')

                    for (let j = 0; j < this.holidays.length; j += 1) {
                        const holiday = this.holidays[j]
                        const dateEnd = moment(holiday.date, 'D MMM')
                        const dateStart = moment(dateEnd).subtract(holiday.daysCount - 1, 'days')

                        if (dateCurrent.isSameOrAfter(dateStart) && dateCurrent.isSameOrBefore(dateEnd)) {
                            text = holidayString(holiday, dateCurrent)

                            if (i >= daysFixed) {
                                this.preorderDays.push(text)
                                holidaysCount += 1
                            }

                            if (i === daysMax - 1) {
                                for (let k = 0; k < holiday.daysCount - holidaysCount; k += 1) {
                                    this.preorderDays.push(holidayString(holiday, moment(dateCurrent).add(k + 1, 'days')))
                                }
                            }
                        }
                    }

                    if (i < daysFixed) {
                        this.preorderDays.push(text)
                    }
                }
            },

            onClickPreorderTimeDelivery() {
                if (this.preorderTimeDeliveryDisable()) {
                    return
                }

                this.order.isNearest = false

                let dateDay

                if (this.preorderDay > 6) {
                    dateDay = this.getDayStr(this.preorderDay)
                    this.holidays.forEach(holiday => {
                        dateDay = dateDay.replace(`${holiday.icon} `, '')
                    })
                } else {
                    dateDay = moment().add(this.preorderDay, 'day').format('D MMM')
                }

                let dateReady = moment(dateDay + ' ' + this.preorderTimeReady, 'D MMM HH:mm')
                if (moment(dateReady, 'D MMM').month() < moment().month()) {
                    // решение проблемы с декабрем и заказом на январь
                    dateReady.add(1, 'y')
                }
                this.order.dateReady = dateReady
                if (this.preorderTimeDeliveryMode == 2) {
                    this.order.dateDelivery = moment(dateDay + ' ' + this.preorderTimeDeliveryTo, 'D MMM HH:mm')
                    this.additionalComment = `указан диапазон: ${this.preorderTimeDeliveryFrom}-${this.preorderTimeDeliveryTo}`
                } else {
                    this.order.dateDelivery = moment(dateDay + ' ' + this.preorderTimeDeliveryFrom, 'D MMM HH:mm')
                    this.additionalComment = null
                }
                this.dialogTypePreorder = false
            },

            preorderTimeDeliveryDisable() {
                if (this.preorderTimeDeliveryMode == 1 && !this.preorderTimeDeliveryFrom) {
                    return true
                }
                if (this.preorderTimeDeliveryMode == 2 && (!this.preorderTimeDeliveryFrom || !this.preorderTimeDeliveryTo)) {
                    return true
                }
                return false
            },

            onClickNearestTime(time) {
                console.log('onClickNearestTime', time)
                this.order.isNearest = true
                this.order.createdAt = moment()
                const dateReady = moment()
                let sec = time * 60

                if (sec < this.timeTake) {
                    sec = this.timeTake
                    const timeString = time ? time + ' мин' : 'уже готов'
                    this.additionalComment = `готовность: ${timeString}`
                }
                dateReady.add(sec, 's')
                this.order.dateReady = dateReady.utc().format()
                const dateDelivery = moment(dateReady)
                // TODO Понять почему тут нужно добавить 1 сек, чтобы время нормально показывало. Поправить.
                dateDelivery.add(this.timeDelivery, 's').add(1, 's')
                this.order.dateDelivery = dateDelivery.utc().format()
                this.dialogTypeNearest = false
            },

            onClickPreorderDay(day) {
                // this.order.isNearest = false
                this.preorderDay = day
                this.preorderStep = 2
            },

            promptCloseYes() {
                this.orderDialog = false
                this.$emit('close')
            },

            onClickCreate() {
                if (this.orderValid()) {
                    this.orderDialog = false
                    if (!this.orderComment) {
                        this.orderComment = ''
                    }

                    if (this.additionalComment) {
                        if (this.orderComment === '') {
                            this.orderComment = this.additionalComment
                        } else {
                            this.orderComment = this.orderComment.replace(this.additionalComment, '')
                            this.orderComment = `${this.additionalComment}, ${this.orderComment}`
                        }
                    }

                    if (this.userOperator) {
                        this.order.commentOperator = this.orderComment
                    } else {
                        this.order.commentPoint = this.orderComment
                    }

                    this.order.address = this.getAddress()
                    this.order.dateTake = null
                    this.order.dateComplete = null
                    this.$emit('create', this.order)
                    this.$emit('close')
                }
            },

            onClickClose() {
                if (!this.promptCloseFlag) {
                    this.orderDialog = false
                    this.$emit('close')
                }
                this.promptClose = true
            },

            distanceString(d) {
                if (!d) d = 0
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },

            distanceNumber(d) {
                if (!d) d = 0
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1)
                }
                return 0
            },

            getAddress() {
                let res = ''
                if (this.order.address) {
                    res = this.order.address.replace(',', '')
                    if (this.additionalAddress.flat) {
                        res += ', кв. ' + this.additionalAddress.flat
                    }
                    if (this.additionalAddress.entrance) {
                        res += ', п. ' + this.additionalAddress.entrance
                    }
                    if (this.additionalAddress.floor) {
                        res += ', эт. ' + this.additionalAddress.floor
                    }
                    if (this.additionalAddress.other) {
                        res += ', ' + this.additionalAddress.other
                    }
                }
                return res
            },

            getTimes() {
                let res = ''
                if (this.order.isNearest !== undefined) {
                    res = `<b>${this.getOrderMode(this.order)}</b>: `
                    res += `забрать <b>до ${this.timeToString(this.order.dateReady, false)}</b>`
                    res += `, доставить <b>к ${this.timeToString(this.order.dateDelivery, false)}</b>`
                }
                return res
            },

            getDayStr(day) {
                let res = ''
                if (day > 0) {
                    res = this.preorderDays[day - 1]
                } else {
                    res = '✅ Сегодня'
                }
                return res
            },

            priceUpdate(latitude, longitude) {
                const pointId = this.point.id
                let data

                if (latitude && longitude) {
                    data = { pointId, latitude, longitude }
                } else {
                    data = { pointId, address: this.order.address }
                }

                this.loadingOrderDialog = true
                this.$store
                    .dispatch('order_price', data)
                    .then(res => {
                        if (res.data.success) {
                            this.order.feePoint = res.data.price.point

                            if (this.courierPrice > 0) {
                                this.order.feeCourier = this.courierPrice
                            } else {
                                this.order.feeCourier = res.data.price.courier
                            }
                            this.pointPrice = this.order.feePoint

                            this.timeTake = res.data.time.take * 60
                            this.timeDelivery = res.data.time.delivery * 60
                            this.order.distance = res.data.distance
                        } else {
                            console.log('order_price fail')
                            this.notifyToast('Не удалось определить стоимость доставки', { color: 'error', timeout: 15000 })
                        }

                        this.loadingOrderDialog = false
                    })
                    .catch(err => {
                        console.log('order_price error', err)
                        this.notifyToast(`Ошибка: ${err.message}`, { color: 'error', timeout: 15000 })

                        this.loadingOrderDialog = false
                    })
            },
            addressWrong() {
                return this.order.address == '' && this.addressEmptySelect
            },

            addressValid() {
                return this.isFoundCoordinates && this.order?.address && this.order.address !== ''
            },

            onAddressWidgetUpdate(data) {
                if (data.isFoundCoordinates) {
                    this.promptCloseFlag = true
                    this.isFoundCoordinates = true

                    if (data.latitude !== this.order.latitude || data.longitude !== this.order.longitude) {
                        this.order.latitude = data.latitude
                        this.order.longitude = data.longitude
                        this.priceUpdate(data.latitude, data.longitude)
                    }
                } else {
                    this.isFoundCoordinates = false
                }

                if (data.address) {
                    this.order.address = data.address
                } else {
                    this.addressEmptySelect = true
                }
            },
        },
    }
</script>

<style>
    .input-point .v-input__slot,
    .input-point input {
        cursor: pointer !important;
    }

    .input-cost {
        width: 100px;
    }

    .input-cost .mdi-currency-rub {
        font-size: 18px !important;
    }

    /* .input-cost .v-input__prepend-outer {
        margin-right: 0 !important;
    } */
    .v-stepper__content {
        padding: 4px 60px 10px 23px !important;
    }

    .v-stepper--vertical {
        padding-bottom: 8px;
    }

    .v-stepper--vertical .v-stepper__step {
        padding: 12px 24px 6px;
    }

    .v-stepper--vertical .v-stepper__content {
        margin: -4px -36px -10px 36px !important;
    }

    .v-stepper__step--active .v-stepper__label {
        text-shadow: none !important;
        font-weight: 600;
    }

    .v-stepper__label {
        display: flex;
        flex-direction: column;
    }

    .v-stepper__step--editable:hover .v-stepper__label {
        text-shadow: none !important;
    }

    .v-stepper__wrapper {
        background: rgba(0, 0, 0, 0.03);
    }
</style>

<style scoped>
    .keyboard {
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        padding: 0 10px !important;
        height: 40px !important;
    }

    .stepper-content {
        margin: 0 !important;
    }

    .step-info {
        line-height: 1.2rem;
        padding-top: 5px !important;
        color: #002b91;
        font-weight: 300;
        min-height: 24px;
    }

    .step-empty {
        line-height: 1.2rem;
        padding-top: 5px !important;
        color: #636363;
        font-weight: 300;
        font-style: italic;
    }

    .order-create-dialog {
        height: 100%;
        width: 100%;
        /* padding-top: 10px !important; */
    }

    .btn-update {
        height: 24px !important;
        min-width: 168px !important;
    }

</style>
