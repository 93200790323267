<template>
    <div id="orders">
        
        <template>
            <v-container class="pa-0">
                <v-snackbar right top :value="snackbarText" :timeout="6000" v-model="snackbar" color="info">
                    <v-progress-circular color="deep-orange lighten-2"
                        :value="Math.floor(100 * (currentTime / snackbarTime))" class="mx-2">
                        <strong>{{ snackbarLoad }}</strong>
                    </v-progress-circular>

                    <span class="mx-2 bold">
                        <b>{{ snackbarText }}</b>
                    </span>

                    <v-btn color="deep-orange " @click="snackRestoreOrder"> <span color="black">Не удалять</span>
                    </v-btn>
                </v-snackbar>
            </v-container>
        </template>

        <v-row style="max-width: 2400px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" class="d-flex overflow-x-auto" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            Всего заказов: {{ getOrders.length }}, доставлено: {{ ordersComplete }}, {{ userIsPoint ?
                            'расходы' : 'выручка' }}: {{ ordersFeePoint }} ₽{{ userIsPoint ? '' : ', зарплата: ' +
                            ordersFeeCourier + ' ₽' }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday">
                                <v-icon>mdi-calendar</v-icon> {{ $t('orders.buttons.today') }}
                            </v-btn>
                            <v-btn value="center" @click="onClickYesterday">
                                <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('orders.buttons.yesterday') }}
                            </v-btn>
                            <v-btn value="right" @click="onClickMonth">
                                <v-icon>mdi-calendar-month</v-icon> {{ $t('orders.buttons.month') }}
                            </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('orders.buttons.range') }}
                                    </v-btn>
                                </template>
                                <div @keydown.esc="closeCalendar" tabindex="0">
                                    <v-date-picker range v-model="dates" locale="ru-ru"
                                        selected-items-text="Выбран период" :first-day-of-week="1"
                                        @change="onClickChangeDate"> </v-date-picker>
                                </div>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <v-dialog scrollable v-model="popupDeletedOrder">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="getDeletedOrders.length === 0" icon>
                                    <v-badge overlap :content="getDeletedOrders.length" :value="getDeletedOrders.length"
                                        color="red">
                                        <v-icon large>mdi-trash-can-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="d-flex justify-space-between text-h5 grey lighten-2">
                                    Удаленные заказы
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" color="primary" text @click="popupDeletedOrder = false">
                                            Закрыть</v-btn>
                                    </v-card-actions>
                                </v-card-title>
                                <template>
                                    <v-card>
                                        <tableDeleted :orders="getDeletedOrders"
                                            @successRestore="restoreStatus"></tableDeleted>
                                    </v-card>
                                </template>
                            </v-card>
                        </v-dialog>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузить заказы в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-row dense justify="start">

                            <v-col v-if="!userIsPoint" cols="12" sm="6" md="2">
                                <v-select class="mx-2 flex-grow-0" style="width: 300px"
                                    no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point"
                                    item-text="name" item-value="id" v-model="filter_pointId" label="По точке"
                                    hide-details></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="2">
                                <v-select
                                    class="mx-2 flex-grow-0"
                                    style="width: 300px"
                                    no-data-text="Нет данных"
                                    @change="updateData"
                                    :items="filter_couriers"
                                    name="courier"
                                    item-text="name"
                                    item-value="id"
                                    v-model="filter_courierId"
                                    label="По курьеру"
                                    hide-details
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="2">
                                <v-select class="mx-2 flex-grow-0" style="width: 300px" no-data-text="Нет данных"
                                    @change="updateData" :items="filter_order_modes" name="order_mode"
                                    v-model="filter_order_mode" label="По типу" hide-details></v-select>
                            </v-col>

                            <v-spacer></v-spacer>

                            <v-col cols="12" sm="6" md="2">
                                <v-btn v-if="!userIsPoint" outlined small tile color="primary"
                                    class="mx-2 mt-3 flex-grow-0" style="height: 35px" @click="clickFeeUpdate()">
                                    Обновить цены<br />на доставку</v-btn>
                            </v-col>

                            <v-spacer></v-spacer>

                            <v-col cols="12" sm="6" md="2">
                                <v-text-field style="width: 300px; flex: initial" v-model="search"
                                    append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">

                                <v-expansion-panels accordion flat>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>

                                            <p class="mb-0">
                                                <b>Среднее время:</b> забора <b>{{ timeMinutesHuman(averageTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(averageTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(averageTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение среднего от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(averageTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(averageTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(averageTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение среднего от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>

                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="mb-0">

                                            <p class="mb-0">
                                                <b>Миним. время:</b> забора <b>{{ timeMinutesHuman(minimumTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(minimumTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(minimumTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(minimumTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(minimumTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(minimumTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>


                                            <p class="mb-0">
                                                <b>Макс. время:</b> забора <b>{{ timeMinutesHuman(maximumTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(maximumTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(maximumTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(maximumTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(maximumTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(maximumTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>

                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        must-sort
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :headers="headers"
                        :search="search"
                        :items="getOrders"
                        :item-class="rowItemClass"
                        item-key="index"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        :sort-by="'dateDelivery'"
                        :sort-desc="true"
                        @click:row="onClickOrderEdit($event)"
                    >
                        <template v-slot:[`item.externalSource`]="{ item }">
                            <b>{{ item.externalSource }}</b>
                        </template>
                        
                        <template v-slot:[`item.source`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-avatar size="24">
                                            <img alt="source" :src="iconSource1(item)" />
                                        </v-avatar>
                                    </td>
                                </template>
                                <span>{{ tooltipSource1(item) }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.name`]="{ item }">
                            <b>{{ item.name }}</b>
                            <!-- <b>{{ item.name ? item.name : getMultiOrderName(item) }}</b> -->
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div class="d-flex">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" :color="orderStatusColorHtml1(item.status)"
                                            text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon1(item.status) }}
                                            </v-icon>
                                            {{ orderStatusStr1(item.status) }}
                                        </v-chip>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent1(item.point, item)"></div>
                                    </v-card>
                                </v-menu>
                                <v-btn class="mt-1"
                                    v-if="!userIsPoint && item.status != OrderStatus.delivered && (item.status != OrderStatus.new || item.courierId)"
                                    outlined text tile x-small @click="onClickOrderStatus(item)">
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-slot:[`item.customerString`]="{ item }">
                            <span style="min-width: 180px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td v-bind="attrs" v-on="on">
                                            <router-link v-if="item.customer"
                                                :to="{ name: 'guests_phone', params: { phone: item.customer.phone } }"
                                                class="text-decoration-none" style="color: inherit">
                                                <span class="py-2 tooltip-text"
                                                    :class="loading || item.status == OrderStatus.forming ? '' : item.customer.phone ? 'guest-selectable' : 'guest-error'"
                                                    @click="onClickCustomer(item)">{{ getCustomerPhoneName(item) }}</span>
                                            </router-link>

                                            <span v-else class="py-2 tooltip-text">{{ getCustomerStr(item.customerString) }}</span>
                                        </td>
                                    </template>
                                    <span>{{ getCustomerStr(item.customerString) }}</span>
                                </v-tooltip>
                                <v-btn class="ml-2" v-if="!item.customerId && item.status != OrderStatus.forming"
                                    outlined tile text x-small @click="onClickCustomerCreate(item)">
                                    <v-icon small>mdi-account-plus-outline</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <template v-slot:[`item.address`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <span
                                            :style="item.feeCourier == 0 || item.feeCourier == $store.state.settings.price_courier_default ? 'color: red' : ''">{{ item.address?.substr(0, 24) ?? '--' }}</span>
                                    </td>
                                </template>
                                <span>{{ item.address }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.feeCourier`]="{ item }">
                            <span
                                :style="item.feeCourier == 0 || item.feeCourier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                {{ +item.feeCourier }}
                            </span>
                        </template>

                        <template v-slot:[`item.feePoint`]="{ item }">
                            <span
                                :style="item.feePoint == 0 || item.feePoint == $store.state.settings.price_point_default ? 'color: red' : ''">
                                {{ +item.feePoint }}
                            </span>
                        </template>

                        <template v-slot:[`item.distance`]="{ item }">
                            <span>
                                {{ distance(item.distance) }}
                            </span>
                        </template>

                        <template v-slot:[`item.courierName`]="{ item }">
                            <v-avatar size="24" class="mr-2" v-if="item.courierName != '--'">
                                <img alt="courier"
                                    :src="`${config.api.delivery + 'user_avatar?id=' + item.courier?.userId ?? 'null'}`" />
                            </v-avatar>
                            <b v-if="userIsPoint">{{ item.courierName.split(' ')[0] }}</b>
                            <b v-else>{{ item.courierName.length > 20 ? item.courierName.substr(0, 20) + '...' :
                            item.courierName }}</b>
                        </template>

                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{ getOrderMode(item, true) }} 
                            {{ timeToString(item.createdAt) }}
                        </template>

                        <template v-slot:[`item.dateReady`]="{ item }">
                            {{ timeToString(item.dateReady) }}
                        </template>

                        <template v-slot:[`item.dateDelivery`]="{ item }">
                            {{ timeToString(item.dateDelivery) }}
                        </template>

                        <template v-slot:[`item.dateTake`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.dateTake) }}</span>
                            <v-chip v-if="item.dateTake" class="px-1 mb-1"
                                :color="timeColorHtml(item.dateTake, item.dateReady)" x-small label
                                text-color="white">
                                {{ timeDiff(item.dateTake, item.dateReady) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.dateComplete`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.dateComplete) }}</span>
                            <v-chip v-if="item.dateComplete" class="px-1 mb-1"
                                :color="timeColorHtml(item.dateComplete, item.dateDelivery)" x-small label
                                text-color="white">
                                {{ timeDiff(item.dateComplete, item.dateDelivery) }}
                            </v-chip>
                        </template>
                    </v-data-table>

                </v-card>
            </v-col>
        </v-row>

        <dialog-order-edit v-if="dialogEdit"
            :data="currentOrder"
            :header="dialogHeader"
            :user-operator="$store.state.user.role === config.user.role.operator"
            :delete-disabled="deleteDisabled"
            :settings="$store.state.settings"
            :couriers="couriers"
            :points="points"
            @save="onOrderSave"
            @delete="onOrderDelete"
            @copy="onOrderCopy"
            @send-complete="onOrderSendComplete"
            @close="dialogEdit = false"
        />

        <dialog-order-create v-if="dialogCreate"
            :data="copyOrder"
            :header="dialogHeader"
            :user-operator="$store.state.user.role === config.user.role.operator"
            :settings="$store.state.settings"
            :points="
                $store.state.user.role === config.user.role.operator
                ? points
                : points.filter(p => p.id == this.$store.state.user.pointId)"
            @create="onOrderCreate"
            @close="dialogCreate = false"
        />

        <v-dialog v-model="dialogStatus" width="unset">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text>
                    <div class="d-flex px-4 py-5 justify-md-space-around">
                        <v-btn class="ma-2"
                            v-if="currentOrder.status == OrderStatus.onWayCustomer || currentOrder.status == OrderStatus.onWayPoint || currentOrder.status == OrderStatus.passed"
                            :color="orderStatusColorHtml1(OrderStatus.new)" dark @click="onClickOrderNew()">Новый
                        </v-btn>

                        <v-btn class="ma-2"
                            v-if="currentOrder.status == OrderStatus.onWayPoint || currentOrder.status == OrderStatus.onWayCustomer || currentOrder.status == OrderStatus.passed || currentOrder.courierId"
                            :color="orderStatusColorHtml1(OrderStatus.delivered)" dark
                            @click="onClickOrderComplete()">Доставлен</v-btn>

                        <v-btn class="ma-2" v-if="currentOrder.status == OrderStatus.forming" color="error" dark
                            @click="onClickOrderDelete()">Удалить</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <span v-html="dialogContent"></span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptFeeUpdate" width="500">
            <v-card>
                <v-card-title class="grey lighten-2"> Подтверждение действия </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы уверены, что хотите <b>обновить цены на доставку для всех выбранных заказов</b>?</span>
                    <br />
                    <span class="red--text">Внимание: результат нельзя будет вернуть назад!</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="promptFeeUpdateYes">Обновить</v-btn>
                    <v-btn class="mx-2" color="error" text @click="promptFeeUpdate = false">Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- TODO Временно отключена возможность точкам супры создавать заказы, так как курьеры используют кабинет -->

        <v-tooltip top open-delay="200">
            <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="loading || ($store.state.userIsPoint && $store.state.user.point.name.includes('Супра'))" color="purple" v-bind="attrs" v-on="on" fab :dark="!loading" class="btn-create"
                    @click="onClickOrderCreate">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Создать новый заказ</span>
        </v-tooltip>

        <span class="pb-12 d-block" />
        <span class="pb-12 d-block" />

    </div>
</template>

<script>
import config from '../../config'
import moment from 'moment-timezone'

moment.locale('ru')

import DateTime from '../../mixins/DateTime.js'
import Status from '../../mixins/Status.js'
import Orders from '../../mixins/Orders.js'
import Notify from '../../mixins/Notify.js'
import tableDeleted from './tableDeleted.vue'
import dialogOrderEdit from './DialogOrderEdit.vue'
import dialogOrderCreate from './DialogOrderCreate.vue'

export default {
    components: {
        tableDeleted,
        dialogOrderEdit,
        dialogOrderCreate,
    },

    mixins: [DateTime, Status, Orders, Notify],

    data() {
        return {

            // snackbar случайно удаленных заказов
            snackbar: false,
            snackbarText: '',
            snackbarTime: 5000,
            currentTime: 0,
            // отключение кнопки удаления (был баг что в если во время snackbar открыть другой заказ и пытаться непрерывно кликать по кнопке удалить (которая в момент удаления disabled), то в момент когда пропадает snackbar и кнопка активируется можно успеть передать параметр закликанного заказа в currentDeleteItem и досрочно отправить этот заказ в нотифай предыдущего удаления. По факту удалился предыдущий заказ а в нотифай полетел закликанный.
            deleteDisabled: false,
            // сбрасывает interval
            snackbarCancelInterval: false,
            // фиксирует удаленный заказ для snackbar (был баг что в процессе удаления, когда висит snackbar, при нажатии на другие заказы он ловил другие заказы и отправлял их в нотифай как удаленные)
            currentDeleteItem: {},

            // переключатель попап удаленных заказов
            popupDeletedOrder: false,
            // данные по удаленным заказам
            deletedOrdersData: [],


            dates: [],
            calendar: 0,
            menu_calendar: false,
            loading: false,
            search: '',
            searchDeleted: '',
            ordersComplete: '--',
            ordersFeePoint: '--',
            ordersFeeCourier: '--',
            orders: [],
            points: [],
            couriers: [],
            filter_pointId: -1,
            filter_points: [
                { id: -1, name: '< Все >' }
            ],
            filter_courierId: -1,
            filter_couriers: [
                { id: -1, name: '< Все >' },
            ],
            filter_order_mode: 0,
            filter_order_modes: [
                {
                    text: '< Все >',
                    value: 0,
                },
                {
                    text: this.getOrderModeString(true),
                    value: 1,
                },
                {
                    text: this.getOrderModeString(false),
                    value: 2,
                },
                {
                    text: '🟩 Не опоздавший',
                    value: 3,
                },
                {
                    text: '🟥 Опоздавший',
                    value: 4,
                },
            ],
            headers: [],
            orderStatuses: [],
            timer: null,
            dialogStatus: false,
            dialogEdit: false,
            dialogCreate: false,
            dialogHeader: '',
            dialogContent: '',
            config,
            currentOrder: {},
            copyOrder: null,
            changeFlag: false,
            promptFeeUpdate: false,
            dateStart: null,
            dateEnd: null,
            userIsPoint: false,
            customerNameClick: false,
            customerCreateClick: false,
            averageTimeTake: 0,
            averageTimeDelivery: 0,
            minimumTimeTake: 0,
            minimumTimeDelivery: 0,
            maximumTimeTake: 0,
            maximumTimeDelivery: 0,
            timerId: null,
            multiOrders: [],
            loadingTimer: null
        }
    },

    created() {
        this.userIsPoint = this.$store.state.user.role != config.user.role.operator
        if (!this.userIsPoint) {
            this.headers = [
                {
                    text: 'Сист.',
                    value: 'source',
                    sortable: false,
                },
                {
                    text: 'Название',
                    value: 'name',
                },
                {
                    text: 'Статус',
                    value: 'status',
                    sort: this.customSortStatus,
                },
                {
                    text: 'Гость',
                    sortable: false,
                    value: 'customerString',
                },
                {
                    text: 'Адрес доставки',
                    sortable: false,
                    value: 'address',
                },
                {
                    text: 'Цена курьера',
                    value: 'feeCourier',
                },
                {
                    text: 'Цена точки',
                    value: 'feePoint',
                },
                {
                    text: 'Расстояние',
                    value: 'distance',
                },
                {
                    text: 'Курьер',
                    value: 'courierName',
                },
                {
                    text: 'Готов',
                    value: 'createdAt',
                },
                {
                    text: 'Забрать',
                    value: 'dateReady',
                },
                {
                    text: 'Доставить',
                    value: 'dateDelivery',
                },
                {
                    text: 'Забран',
                    value: 'dateTake',
                },
                {
                    text: 'Доставлен',
                    value: 'dateComplete',
                },
            ]
        } else {
            this.headers = [
                {
                    text: 'Сист.',
                    value: 'source',
                    sortable: false,
                },
                {
                    text: 'Название',
                    value: 'name',
                },
                {
                    text: 'Статус',
                    value: 'status',
                    sort: this.customSortStatus,
                },
                {
                    text: 'Гость',
                    sortable: false,
                    value: 'customerString',
                },
                {
                    text: 'Адрес доставки',
                    sortable: false,
                    value: 'address',
                },
                {
                    text: 'Цена',
                    value: 'feePoint',
                },
                {
                    text: 'Расстояние',
                    value: 'distance',
                },
                {
                    text: 'Курьер',
                    value: 'courierName',
                },
                {
                    text: 'Готов',
                    value: 'createdAt',
                },
                {
                    text: 'Забрать',
                    value: 'dateReady',
                },
                {
                    text: 'Доставить',
                    value: 'dateDelivery',
                },
                {
                    text: 'Забран',
                    value: 'dateTake',
                },
                {
                    text: 'Доставлен',
                    value: 'dateComplete',
                },
            ]
        }

        if (this.$store.state.settings.marketingSourceView) {
            this.headers.splice(1, 0, {
                text: 'Источник',
                value: 'externalSource',
            })
        }
    },

    mounted() {
        console.log('orders mount')
        this.dates[0] = moment().format('YYYY-MM-DD')
        this.dates[1] = moment().format('YYYY-MM-DD')
        this.dateStart = this.getDate1String()
        this.dateEnd = this.getDate2String()
        this.updateData()
    },

    beforeDestroy() { },

    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
        if (this.loadingTimer) {
            clearInterval(this.loadingTimer)
            this.loadingTimer = null
        }
        // return this.timer ? clearInterval(this.timer) : false
    },

    watch: {},

    computed: {
        getOrders() {
            return this.orders.filter(order => !order.isDeleted).map(order => {
                const point = this.points.find(point => point.id === order.pointId)
                const courier = this.couriers.find(courier => courier.id === order.courierId)
                return {
                    ...order,
                    name: this.getOrderName(order, point),
                    courierName: this.getOrderCourierName(order, courier),
                    point,
                    courier,
                }
            })
        },
        getDeletedOrders() {
            return this.orders.filter(order => order.isDeleted).map(order => {
                const point = this.points.find(point => point.id === order.pointId)
                const courier = this.couriers.find(courier => courier.id === order.courierId)
                return {
                    ...order,
                    name: this.getOrderName(order, point),
                    courierName: this.getOrderCourierName(order, courier),
                    point,
                    courier,
                }
            })
        },
        // активирует/блокирует корзину и показывает число удаленных заказов на ней
        snackbarLoad() {
            let result = Math.floor(5 - (100 * (this.currentTime / this.snackbarTime)) / 20)
            if (result >= 0) {
                return result
            }
            return 0
        },
    },

    methods: {
        customSortStatus(a, b) {
            const statusNumbers = {
                'new': 1,
                'passed': 2,
                'onWayPoint': 3,
                'onWayCustomer': 4,
                'delivered': 5,
                'forming': 6,
            }

            let sort = 0
            if (statusNumbers[a] > statusNumbers[b]) sort = 1
            else if (statusNumbers[a] < statusNumbers[b]) sort = -1

            return sort
        },

        getMultiOrderName(item) {
            // return item.orders.reduce((acc, i) => {
            //     acc += `${i.name}, `
            //     return acc
            // }, '').slice(0, -2)
            return ''
        },

        getPointsFiltered() {
            let points
            if (this.$store.state.user.role === config.user.role.operator) {
                points = this.filter_points
            } else {
                points = this.filter_points.filter(p => p.id == this.$store.state.user.pointId)
            }
            return points
        },

        // возвращает удаленный заказ обратно в список
        snackRestoreOrder() {
            this.snackbar = false
            this.snackbarCancelInterval = true

            const restoreOrder = this.currentDeleteItem
            const indexOrder = this.currentDeleteItem.indexOrder
            delete this.currentDeleteItem.indexOrder

            this.deleteDisabled = false

            if (this.currentDeleteItem.isCombined) {
                return this.multiOrders.splice(indexOrder, 0, restoreOrder)
            } else {
                return this.orders.splice(indexOrder, 0, restoreOrder)
            }
        },

        // запускает таймер по восстановлению заказа. По окончании отправляет notify в телеграмм бот что заказ удален
        syncPbar() {
            console.log('start syncPbar')

            // останавливаем отправку notify если нажали кнопку восстановления
            if (this.snackbarCancelInterval) {
                this.snackbarCancelInterval = false
                if (this.timerId) {
                    clearTimeout(this.timerId)
                    this.timerId = null
                }
                return
            }

            this.timerId = setTimeout(() => {
                // Increment the time counter by 100
                this.currentTime += 1000
                this.syncPbar()
            }, 1000)

            if (this.currentTime > this.snackbarTime) {
                this.snackbar = false
            }
            if (this.currentTime > this.snackbarTime + 1200 && this.snackbarCancelInterval === false) {
                if (this.timerId) {
                    clearTimeout(this.timerId)
                    this.timerId = null
                }
                // Запускает удаление
                this.deleteOrder()
            }
        },

        // получает данные от ребенка (tableDeleted), закрывая попап и обновляя данные если true
        restoreStatus(data) {
            console.log('test restore data = ', data)
            if (data.status) {
                this.updateData()
                // this.popupDeletedOrder = false
                return this.restoreSuccess(data.name)
            } else {
                console.log('restore false')
                return this.restoreError(data.name)
            }
        },

        restoreSuccess(name) {
            console.log('test restoreSuccess = ', name)
            const text = `Заказ ${name} успешно восстановлен`
            this.notifyToast(text, { color: 'success', timeout: 5000 })
        },

        restoreError(name) {
            const text = `Ошибка восстановления заказа ${name}`
            this.notifyToast(text, { color: 'error', timeout: 15000 })
        },

        setTimer() {
            if (this.count > 0) {
                setTimeout(() => {
                    this.count -= 1
                    console.log('test count', this.count)
                    this.setTimer()
                }, 1000)
            }
        },


        onClickCustomer(order) {
            if (order.customer) {
                this.customerNameClick = true
                this.notifyToast(`Гость: ${order.customer.firstName}, ${order.customer.phone}`, { color: 'info', timeout: 4000 })
                setTimeout(() => {
                    this.customerNameClick = false
                }, 100)
            }
        },

        onClickCustomerCreate(order) {
            this.customerCreateClick = true
            this.$store.state.loading = true

            this.$store
                .dispatch('customer_create', order.id)
                .then(res => {
                    if (res.data.success) {
                        order.customer = res.data.customer
                        order.customerId = res.data.customer.id
                        this.notifyToast('Успешно создан гость ' + order.customer.firstName, { color: 'success', timeout: 5000 })
                    } else {
                        this.notifyToast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 15000 })
                    }
                    this.customerCreateClick = false
                    this.$store.state.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.customerCreateClick = false
                    this.$store.state.loading = false
                    this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                })
        },

        getCustomerPhoneName(order) {
            let customerName = ''
            if (order.customer) {
                customerName += order.customer.phone
                if (order.customer.firstName) customerName += ' - ' + order.customer.firstName
            } else {
                if (order.customerString) {
                    customerName = this.getCustomerStr(order.customerString).substr(0, 24)
                }
            }
            return customerName
        },

        // вызывает snackbar быстрой отмены удаления
        snackbarStart(index) {
            if (this.timerId) {
                clearTimeout(this.timerId)
                this.timerId = null
                this.deleteOrder()
            }

            //отключение кнопок удаления на время snackbar
            this.deleteDisabled = true
            const orderId = this.currentDeleteItem.id
            // находим индекс заказа, что бы вставить его туда же откуда взяли
            const indexOrder = !this.currentDeleteItem.isCombined  ? this.orders.findIndex(order => order.id === orderId) : this.multiOrders.findIndex(multiOrder => multiOrder.id === orderId)
            console.log('index order?', this.currentDeleteItem)
            //добавляем в заказ на случай восстановления через snackbar
            this.currentDeleteItem.indexOrder = indexOrder
            //удаляем заказ из списка
            if (this.currentDeleteItem.isCombined) {
                this.multiOrders = this.multiOrders.filter(multiOrder => multiOrder.id != orderId)
            } else {
                this.orders = this.orders.filter(order => order.id != orderId)
            }

            this.snackbar = true
            this.currentTime = 0
            this.snackbarText = `Заказ ${this.currentDeleteItem.name}`

            this.syncPbar()
        },

        deleteOrder() {
            console.log('this.currentDeleteItem', this.currentDeleteItem)
            //удаляем индекс от snackbar
            delete this.currentDeleteItem.indexOrder
            //передаем статус удаления
            const orderName = this.currentDeleteItem.name

            this.$store
                .dispatch('order_delete', this.currentDeleteItem.id)
                .then(res => {
                    if (res.data.success) {
                        console.log('order delete ok')
                        this.updateData()
                        this.deleteDisabled = false
                    } else {
                        console.log('order delete fail')
                        this.notifyToast(`Не удалось удалить заказ ${orderName} - ${res.data.msg}`, { color: 'error', timeout: 15000 })
                    }
                })
                .catch(err => {
                    console.log('order_delete error', err.name, err.message)
                    this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                })
            // if (order.isCombined) {
            //     this.$store
            //         .dispatch('multi_order_delete', order.id)
            //         .then(res => {
            //             if (res.data.success) {
            //                 console.log('multi_order delete ok', res.data)
            //                 // this.getDeletedAllTypesOrders()
            //                 this.deleteDisabled = false
            //             } else {
            //                 console.log('multi_order delete fail')
            //             }
            //         })
            //         .catch(err => {
            //             console.log('multi_order_delete error', err.name, err.message)
            //         })
            // } else {
            // }
        },

        onOrderClose() {
            console.log('order close')
        },

        onOrderDelete() {
            this.currentDeleteItem = this.currentOrder
            this.snackbarStart()
        },

        async onOrderCopy(order) {
            this.dialogHeader = `Копия заказа`
            this.copyOrder = order
            this.dialogCreate = true
        },

        async onOrderSendComplete(data) {
            // if (data.success) {
            //     this.dialogEdit = false
            //     this.updateData()
            // }
        },

        async onOrderCreate(data) {
            console.log('onOrderCreate', data)
            this.loading = true

            const res = await this.$store.dispatch('order_create', {
                ...data,
                status: this.OrderStatus.new,
                source: this.OrderSource.web,
                isDelivery: true,
                isCombined: false,
                externalType: null,
                externalSource: null,
            })
            if (res && res.data.success) {
                const order = JSON.parse(JSON.stringify(res.data.order))
                this.orders.push(order)
                console.log('order create ok', order)
                this.notifyToast(`Новый заказ создан успешно`, { color: 'success', timeout: 5000 })
            } else {
                this.notifyToast(`Не удалось создать заказ - ошибка (${res.data.msg})`, { color: 'error', timeout: 15000 })
            }

            this.loading = false
        },

        onOrderSave(data) {
            const { order } = data

            let orderSave = {
                id: order.id,
            }

            let courierChangeFlag = false

            if (this.currentOrder.distance != order.distance) {
                this.currentOrder.distance = order.distance
                orderSave.distance = order.distance
                this.changeFlag = true
            }
            if (this.currentOrder.dateReady != order.dateReady) {
                this.currentOrder.dateReady = order.dateReady
                orderSave.dateReady = order.dateReady
                this.changeFlag = true
            }
            if (this.currentOrder.dateDelivery != order.dateDelivery) {
                this.currentOrder.dateDelivery = order.dateDelivery
                orderSave.dateDelivery = order.dateDelivery
                this.changeFlag = true
            }
            if (this.currentOrder.customerString != order.customerString) {
                this.currentOrder.customerString = order.customerString
                orderSave.customerString = order.customerString
                this.changeFlag = true
            }
            if (this.currentOrder.address != order.address) {
                this.currentOrder.address = order.address
                orderSave.address = order.address
                this.changeFlag = true
            }
            if (this.currentOrder.latitude != order.latitude) {
                this.currentOrder.latitude = order.latitude
                orderSave.latitude = order.latitude
                this.changeFlag = true
            }
            if (this.currentOrder.longitude != order.longitude) {
                this.currentOrder.longitude = order.longitude
                orderSave.longitude = order.longitude
                this.changeFlag = true
            }
            if (this.currentOrder.commentOperator != order.commentOperator) {
                this.currentOrder.commentOperator = order.commentOperator
                orderSave.commentOperator = order.commentOperator
                this.changeFlag = true
            }
            if (this.currentOrder.commentPoint != order.commentPoint) {
                this.currentOrder.commentPoint = order.commentPoint
                orderSave.commentPoint = order.commentPoint
                this.changeFlag = true
            }
            if (this.currentOrder.commentCourier != order.commentCourier) {
                this.currentOrder.commentCourier = order.commentCourier
                orderSave.commentCourier = order.commentCourier
                this.changeFlag = true
            }
            if (this.currentOrder.commentCustomer != order.commentCustomer) {
                this.currentOrder.commentCustomer = order.commentCustomer
                orderSave.commentCustomer = order.commentCustomer
                this.changeFlag = true
            }
            if (this.currentOrder.courierId != order.courierId) {
                this.currentOrder.courierId = order.courierId
                this.currentOrder.status = order.status
                this.currentOrder.dateTake = order.dateReady
                this.currentOrder.dateComplete = order.dateDelivery
                orderSave.courierId = order.courierId
                orderSave.status = order.status
                orderSave.dateTake = order.dateReady
                orderSave.dateComplete = order.dateDelivery
                this.changeFlag = true
                courierChangeFlag = true
            }

            const payCost = parseInt(order.payCost)
            if (!isNaN(order.payCost) && payCost > 0) {
                if (this.currentOrder.payCost != payCost) {
                    this.currentOrder.payCost = payCost
                    orderSave.payCost = payCost
                    this.changeFlag = true
                }
            }

            const feeCourier = parseInt(order.feeCourier)
            if (feeCourier || feeCourier == 0) {
                if (order.dateComplete) {
                    this.ordersFeeCourier += feeCourier - this.currentOrder.feeCourier
                }
                this.currentOrder.feeCourier = feeCourier
                orderSave.feeCourier = feeCourier
                this.changeFlag = true
            }

            const feePoint = parseInt(order.feePoint)
            if (feePoint || feePoint == 0) {
                if (order.dateComplete) {
                    this.ordersFeePoint += feePoint - this.currentOrder.feePoint
                }
                this.currentOrder.feePoint = feePoint
                orderSave.feePoint = feePoint
                this.changeFlag = true
            }

            if (this.changeFlag) {
                this.$store
                    .dispatch('order_update', {
                        orderId: orderSave.id,
                        order: orderSave,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.updateData()
                            console.log('order saved ok')
                            this.notifyToast(`Заказ ${order.name} обновлен успешно`, { color: 'success', timeout: 5000 })
                        } else {
                            console.log('order saved fail')
                            this.notifyToast(`Обновление заказа ${order.name} не выполнено - ${res.data.msg}`, { color: 'error', timeout: 15000 })
                        }
                        this.changeFlag = false
                    })
                    .catch(err => {
                        console.log('order saved error', err.name, err.message)
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                    })
            }

            // if (order.isCombined) {
            //     // У мультизаказа может поменяться только время доставки и забора
            //     const { date_ready, date_delivery, id } = order

            //     this.$store
            //         .dispatch('multi_order_set', {
            //             date_ready,
            //             date_delivery,
            //             id,
            //             orders: order.orders,
            //         })
            //         .then(res => {
            //             if (res.data.success) {
            //                 console.log('multiOrder saved ok')

            //                 this.updateData()
            //             } else {
            //                 console.log('multiOrder saved fail')
            //             }
            //         })
            //         .catch(err => {
            //             console.log('multi_order_set error', err.name, err.message)
            //         })


            //     return
            // } else {
            //     // тут есть нюанс. была ситуация: заказ в статусе создается, открыли этот диалог, в боте поменяли статус заказа на новый, диалог закрыли -> статус заказа обновился с новый на создается и при этом заказ пропадает в боте в списке необработанных заказов, теперь сюда внесены изменения по проверке изменения полей и обновлению заказа в апи только при изменении этих полей. Но это не решает проблему до конца, так как если поля поменяют, то произойдет так же пропажа заказа в боте. решение - обновлять только поля guest, finish_addr и fee_courier, но для этого требуется в api поменять функцию updateOrder - принято еще другое решение: делается запрет на открытие диалога при заказе со статусом формируется

            //     // ! а еще произошла интересней проблема - заказ открыли для редактирования, у заказа status = 2, в этот момент курьер доставил заказ (сделал status = 3), в форме поменяли адрес и комментарий, нажали сохранить и перезаписали status на недоставленный, потом курьеру пришлось еще раз закрывать заказ. это привело к тому, что заказ стал сильно запоздалым и отправились 2 смс с отзывом

            //     // ! проверить как работает этот fix

            // }
        },

        onClickOrderCreate() {
            this.dialogHeader = `Новый заказ`
            this.copyOrder = null
            this.dialogCreate = true
        },

        onClickOrderEdit(item) {
            console.log('onClickOrderItem')
            if (this.dialogStatus || this.customerNameClick || this.customerCreateClick) return
            if (item.status == this.OrderStatus.forming) {
                this.dialogHeader = `Заказ ${item.name} создается...`
            } else {
                this.dialogHeader = `${item.isCombined ? `Мультизаказ ${item.name}` : `Заказ ${item.name}`}`
            }
            this.currentOrder = item
            console.log('this.dialogEdit = true')
            this.dialogEdit = true
        },

        onClickOrderStatus(item) {
            this.dialogHeader = `Изменить статус для ${item.name}`
            this.dialogContent = `Текущий статус заказа: <span style="color: ${this.orderStatusColorHtml1(item.status)};">${this.orderStatusStr1(item.status)}</span>`
            this.currentOrder = item
            this.dialogStatus = true
        },

        onClickOrderNew() {
            this.$store
                .dispatch('order_revoke', {
                    orderId: this.currentOrder.id,
                })
                .then(res => {
                    if (res.data.success) {
                        console.log('order revoked ok')
                        this.currentOrder.status = this.OrderStatus.new
                        this.currentOrder.courierId = null
                        this.currentOrder.courier = undefined
                        this.notifyToast(`Заказ ${this.currentOrder.name} сброшен на новый успешно`, { color: 'success', timeout: 5000 })
                    } else {
                        console.log('order revoked fail')
                        this.notifyToast(`Сброс заказа ${this.currentOrder.name} не выполнен - ${res.data.msg}`, { color: 'error', timeout: 15000 })
                    }
                    this.dialogStatus = false
                })
                .catch(err => {
                    console.log('order revoked error', err.name, err.message)
                    this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                    this.dialogStatus = false
                })
        },

        onClickOrderComplete() {
            this.$store
                .dispatch('order_complete', {
                    orderId: this.currentOrder.id,
                    courierId: this.currentOrder.courierId,
                })
                .then(res => {
                    if (res.data.success) {
                        console.log('order completed ok')
                        this.currentOrder.status = this.OrderStatus.delivered
                        this.currentOrder.dateTake = this.currentOrder.dateReady
                        this.currentOrder.dateComplete = this.currentOrder.dateDelivery
                        this.notifyToast(`Статус доставлен для заказа ${this.currentOrder.name} установлен успешно`, { color: 'success', timeout: 5000 })
                    } else {
                        console.log('order completed fail')
                        this.notifyToast(`Не удалось установить статус доставлен для заказа ${this.currentOrder.name} - ${res.data.msg}`, { color: 'error', timeout: 15000 })
                    }
                    this.dialogStatus = false
                })
                .catch(err => {
                    console.log('order completed error', err.name, err.message)
                    this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                    this.dialogStatus = false
                })
        },

        onClickOrderDelete() {
            this.currentDeleteItem = this.currentOrder
            this.dialogStatus = false
            this.snackbarStart()
        },

        onClickChangeDate(arg) {
            this.dateStart = this.getDate1StringFromDate(this.dates[0])
            this.dateEnd = this.getDate2StringFromDate(this.dates[1])
            this.updateData()
        },

        onClickDownloadData() {
            let url = `${config.api.delivery}order/export?organizationId=${this.$store.state.user.organizationId}&dateStart=${this.dateStart}&dateEnd=${this.dateEnd}&isSortByDelivery=true&statuses=["new","passed","onWayPoint","onWayCustomer","delivered"]&isDeleted=false`
            if (this.userIsPoint) {
                url += `&pointId=${this.$store.state.user.pointId}`
            } else if (this.filter_pointId !== -1) {
                url += `&pointId=${this.filter_pointId}`
            }
            if (this.filter_courierId !== -1) {
                url += `&courierId=${this.filter_courierId}`
            }
            if (this.filter_order_mode == 1) {
                url += `&isNearest=true`
            } else if (this.filter_order_mode == 2) {
                url += `&isNearest=false`
            } else if (this.filter_order_mode == 3) {
                url += `&isLatecomer=false`
            } else if (this.filter_order_mode == 4) {
                url += `&isLatecomer=true`
            }
            if (!this.userIsPoint) {
                url += '&isOperator=true'
            }
            window.location.href = url
        },

        onClickToday() {
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

            this.dateStart = this.getDate1String()
            this.dateEnd = this.getDate2String()
            this.updateData()
        },

        onClickYesterday() {
            this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
            this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')

            this.dateStart = this.getDate1String(-1)
            this.dateEnd = this.getDate2String(-1)
            this.updateData()
        },

        onClickMonth() {
            this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

            this.dateStart = this.getDate1String(-30)
            this.dateEnd = this.getDate2String()
            this.updateData()
        },

        onClickUpdate() {
            this.updateData()
        },

        getCustomerStr(customer) {
            if (customer) {
                let splittedStr = customer.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 17) : customer
            }
            return '--'
        },

        updateData() {
            this.loading = true
            this.ordersComplete = 0
            this.ordersFeeCourier = 0
            this.ordersFeePoint = 0

            this.averageTimeTake = 0
            this.averageTimeDelivery = 0

            this.minimumTimeTake = 0
            this.minimumTimeDelivery = 0

            this.maximumTimeTake = 0
            this.maximumTimeDelivery = 0

            let loading_count = 0

            console.log('UPDATE ORDERS START')

            let isNearest = undefined
            let isLatecomer = undefined
            if (this.filter_order_mode == 1) {
                isNearest = true
            } else if (this.filter_order_mode == 2) {
                isNearest = false
            } else if (this.filter_order_mode == 3) {
                isLatecomer = false
            } else if (this.filter_order_mode == 4) {
                isLatecomer = true
            }

            let pointId = this.$store.state.user.pointId
            if (!this.userIsPoint) {
                pointId = this.filter_pointId !== -1 ? this.filter_pointId : undefined
            }
            let courierId = this.filter_courierId !== -1 ? this.filter_courierId : undefined

            this.$store
                .dispatch('order_list', {
                    dateStart: this.dateStart,
                    dateEnd: this.dateEnd,
                    pointId,
                    courierId,
                    isNearest,
                    isLatecomer,
                    isSortByDelivery: true,
                    isReverse: true,
                    statuses: [
                        this.OrderStatus.forming,
                        this.OrderStatus.new,
                        this.OrderStatus.passed,
                        this.OrderStatus.onWayPoint,
                        this.OrderStatus.onWayCustomer,
                        this.OrderStatus.delivered,
                    ],
                })
                .then(res => {
                    if (res.data.success) {
                        this.orders = res.data.orders
                        this.multiOrders = []

                        let feeCourier = 0
                        let feePoint = 0

                        let takeSum = 0
                        let takeCount = 0
                        let deliverySum = 0
                        let deliveryCount = 0

                        if (this.orders.length && this.multiOrders.length) {
                            this.minimumTimeTake = 1000000
                            this.minimumTimeDelivery = 1000000
                        } else {
                            this.minimumTimeTake = 0
                            this.minimumTimeDelivery = 0
                        }

                        this.orderStatuses[this.OrderStatus.forming] = 0
                        this.orderStatuses[this.OrderStatus.new] = 0
                        this.orderStatuses[this.OrderStatus.passed] = 0
                        this.orderStatuses[this.OrderStatus.onWayPoint] = 0
                        this.orderStatuses[this.OrderStatus.onWayCustomer] = 0
                        this.orderStatuses[this.OrderStatus.delivered] = 0
                        
                        for (let order of [...this.orders, ...this.multiOrders]) {
                            if (order.isDeleted) {
                                continue
                            }

                            this.orderStatuses[order.status]++

                            if (order.dateTake) {
                                if (order.isNearest) {
                                    const t = moment(order.dateTake).diff(order.createdAt, "minutes")
                                    if (t > 0 && t < 240) { // от 1 минуты до 4 часов только учитываем
                                        if (t < this.minimumTimeTake) this.minimumTimeTake = t
                                        if (t > this.maximumTimeTake) this.maximumTimeTake = t
                                        takeSum += t
                                        takeCount += 1
                                    }
                                }
                                if (order.dateComplete) {
                                    const t = moment(order.dateComplete).diff(order.dateTake, "minutes")
                                    if (t > 0 && t < 240) { // от 1 минуты до 4 часов только учитываем
                                        if (t < this.minimumTimeDelivery) this.minimumTimeDelivery = t
                                        if (t > this.maximumTimeDelivery) this.maximumTimeDelivery = t
                                        deliverySum += t
                                        deliveryCount += 1
                                    }
                                }
                            }

                            if (order.dateComplete) {
                                const f_courier = parseInt(order.feeCourier)
                                const f_point = parseInt(order.feePoint)
                                if (f_courier) feeCourier += f_courier
                                if (f_point) feePoint += f_point
                            }
                        }

                        if (takeCount) {
                            this.averageTimeTake = +(takeSum / takeCount).toFixed(0)
                        } else {
                            this.minimumTimeTake = 0
                        }
                        if (deliveryCount) {
                            this.averageTimeDelivery = +(deliverySum / deliveryCount).toFixed(0)
                        } else {
                            this.minimumTimeDelivery = 0
                        }

                        this.ordersFeeCourier = feeCourier
                        this.ordersFeePoint = feePoint

                        this.ordersComplete = this.orderStatuses[this.OrderStatus.delivered]
                    }

                    loading_count++
                    console.log('ORDERS LOADING OK')

                })
                .catch(err => {
                    console.error('error 2', err.name, err.message)
                    loading_count++
                })

                if (!this.couriers.length) {
                    this.$store
                        .dispatch('courier_list', {})
                        .then(res => {
                            this.couriers = []
                            if (res.data.success) {
                                console.log('COURIERS LOADING OK', res.data.couriers.length)
                                if (this.userIsPoint) {
                                    this.couriers = res.data.couriers.filter(courier => courier.user?.isEnabled && courier.user?.pointId === pointId)
                                } else {
                                    this.couriers = res.data.couriers.filter(courier => courier.user?.isEnabled)
                                }
                                console.log('COURIERS FILTER', this.couriers.length)

                                if (this.couriers.length) {
                                    this.filter_couriers = this.couriers
                                        .filter(courier => courier.user?.role === 'courier')
                                        .map(c => {
                                            const name = this.userIsPoint ? `${c.user?.firstName}` : `${c.user?.firstName ?? '-'} ${c.user?.lastName ?? '-'}`
                                            return ({ id: c.id, name })
                                        })
                                    this.filter_couriers.splice(0, 0, { id: -1, name: '< Все >' })
                                    this.filter_couriers.splice(1, 0, { id: null, name: '< Не назначен >' })
                                }
                            } else {
                                console.log('COURIERS LOADING FAIL')
                            }
                            
                            loading_count++
                        })
                        .catch(err => {
                            console.error('error 4', err.name, err.message)
                            loading_count++
                        })
                } else {
                    loading_count++
                }

            if (!this.points.length) {
                this.$store
                    .dispatch('point_list', {})
                    .then(res => {
                        if (res.data.success) {
                            this.points = res.data.points

                            this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                            this.filter_points.splice(0, 0, { id: -1, name: '< Все >' })
                        }

                        console.log('POINTS LOADING OK', this.points.length)

                        loading_count++
                    })
                    .catch(err => {
                        console.error('error 3', err.name, err.message)
                        loading_count++
                    })
            } else {
                loading_count++
            }

            const loadingControl = () => {
                console.log('loading_count', loading_count)
                if (loading_count < 3) {
                    this.loadingTimer = setTimeout(() => loadingControl(), 500)
                } else {
                    this.loadingTimer = null
                    this.loading = false
                    console.log('LOADING RELEASE')
                }
            }

            loadingControl()
        },

        rowItemClass(item) {
            if (item.orders) return 'combined-order'

            return ''
        },

        closeCalendar() {
            this.menu_calendar = false
        },

        clickFeeUpdate() {
            this.promptFeeUpdate = true
        },

        promptFeeUpdateYes() {
            this.promptFeeUpdate = false
            this.feeUpdate()
        },

        feeUpdate() {
            this.loading = true
            this.$store
                .dispatch('orders_fee_calculate', {
                    date1: this.dateStart,
                    date2: this.dateEnd,
                })
                .then(res => {
                    this.loading = false
                    if (res.data.success) {
                        this.updateData()
                        this.notifyToast('Цены успешно обновлены', { color: 'success', timeout: 5000 })
                    } else {
                        this.notifyToast(`Не удалось обновить цены - ${res.msg}`, { color: 'error', timeout: 15000 })
                    }
                })
                .catch(err => {
                    console.log('fee update error', err.name, err.message)
                    this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                    this.loading = false
                })
        },

        distance(d) {
            if (!d) d = 0
            const distance = parseFloat(d)
            if (distance) {
                return (distance / 1000).toFixed(1) + ' км'
            }
            return '--'
        },

        marketingSource(m) {
            if (m) {
                return m
            }
            return '--'
        },
    },
}
</script>

<style lang="stylus" scoped>

    .v-item-group.v-expansion-panels {
        &,
        & .v-expansion-panel,
        & .v-expansion-panel-header,
        & .v-expansion-panel-content {
            transition: none !important;
        }
    }

    .btn-create {
        position: fixed;
        right: 30px;
        bottom: 30px;
    }

    .combined-order
        background-color: #fbe6ff
    .combined-order:hover
        background-color: #eed3f3 !important
    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    #orders
        padding-top 10px !important
        .v-expansion-panel-header {
            font-size: 0.92rem;
            font-weight: 400;
            padding: 8px;
            width: auto;
            min-height: 0;
        }
        .v-expansion-panel-content {
            font-size: 0.92rem;
            font-weight: 400;
            padding: 8px;
            padding-top: 0;
        }
        .v-expansion-panel--active > .v-expansion-panel-header {
            min-height: 0;
            padding-bottom: 5px;
            background-color: #eee;
        }

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                margin-left 0
                cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
