<template>
    <div class="input-with-hints-wrapper">

        <span class="clear-icon">
            <div v-if="loader && loaderFlag" class="hints-waiting-loader" :style="{'--loaderColor': loaderColor}">
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
                <div class="hints-loader__stick" />
            </div>

                <!-- id="new-addr-input" -->
                <!-- type="text" -->
            <input
                ref="addressInput"
                v-model="addressValue"
                class="input-delivery-modal"
                :class="{
                    'style-input_error': errorAddress ? errorAddress : '',
                    'input-delivery-modal__searching': loader,
                }"
                :placeholder="errorAddress ? errorAddress : placeholder"
                :autocomplete="isChrome ? 'new-password' : 'off'"
                @input="onInput"
                @focusin="hideHints = false"
                @focusout="hideHintsRelease"
            />

            <div class="fixed-address__container">
                <div v-show="addressValid" :class="{ 'address-from-map__wrapper': true }">
                    <div
                        class="address-from-map"
                        :class="{
                            'input-delivery-modal__searching': loader,
                        }"
                    >
                        <input id="test-inp" v-model="addressValue" class="address-from-map__text address-from-map__input" type="text" :autocomplete="isChrome ? 'new-password' : 'off'" readonly @input="onInput($event)" />

                        <div v-show="splittedAddressView" class="fixed-address-postfix">
                            <div ref="houseNumberPostfix" class="house-number-postfix" />
                        </div>
                    </div>
                </div>
            </div>

            <span v-if="clearable && addressValue" :class="addressValid ? 'fixed-clear-icon' : ''">
                <button @click="clearAll">
                    <svg v-if="addressValid" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M11.7207 13.6089C12.2421 14.1304 13.0875 14.1304 13.6089 13.6089C14.1304 13.0875 14.1304 12.2421 13.6089 11.7207L8.88825 6.99999L13.6089 2.27933C14.1303 1.7579 14.1303 0.9125 13.6089 0.391071C13.0875 -0.130358 12.2421 -0.130356 11.7206 0.391073L6.99999 5.11173L2.27936 0.391095C1.75793 -0.130334 0.912521 -0.130334 0.391091 0.391096C-0.130338 0.912525 -0.130337 1.75793 0.391093 2.27936L5.11172 6.99999L0.391072 11.7206C-0.130357 12.2421 -0.130357 13.0875 0.391072 13.6089C0.912501 14.1303 1.75791 14.1303 2.27934 13.6089L6.99999 8.88825L11.7207 13.6089Z"
                            fill="#eee"
                        />
                    </svg>

                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M11.7207 13.6089C12.2421 14.1304 13.0875 14.1304 13.6089 13.6089C14.1304 13.0875 14.1304 12.2421 13.6089 11.7207L8.88825 6.99999L13.6089 2.27933C14.1303 1.7579 14.1303 0.9125 13.6089 0.391071C13.0875 -0.130358 12.2421 -0.130356 11.7206 0.391073L6.99999 5.11173L2.27936 0.391095C1.75793 -0.130334 0.912521 -0.130334 0.391091 0.391096C-0.130338 0.912525 -0.130337 1.75793 0.391093 2.27936L5.11172 6.99999L0.391072 11.7206C-0.130357 12.2421 -0.130357 13.0875 0.391072 13.6089C0.912501 14.1303 1.75791 14.1303 2.27934 13.6089L6.99999 8.88825L11.7207 13.6089Z"
                            fill="#4c447f"
                        />
                    </svg>
                </button>
            </span>
        </span>

        <transition>
            <div v-if="!hideHints && hints.length && !clearHints" class="hints-input-modal-mask">
                <ul class="hints-list" @touchstart="onTouchStart($event)" @touchmove.passive="onTouchMove($event)">
                    <li v-for="item, index in hints" :key="index" class="hints address-hint__container" @click="onSelectAddressHint(item)">
                        <div class="hints-address__street">
                            {{ item.address }}
                        </div>
                        <div class="hints-address__region">Россия{{ item.region ? ', ' + item.region : ', Владивосток ' }}</div>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            value: {
                type: String,
                default: '',
            },

            errorAddress: {
                type: String,
                default: '',
            },

            clearable: {
                type: Boolean,
                default: false,
            },

            loader: {
                type: Boolean,
                default: false,
            },

            loaderStatus: {
                type: Boolean,
                default: false,
            },

            clearHints: {
                type: Boolean,
                required: true,
            },

            placeholder: {
                type: String,
                default: 'Начните писать адрес',
            },
        },
        emits: ['set-position', 'clear', 'select', 'loader-change', 'valid'],
        data() {
            return {
                hints: [],
                selectAddress: false,
                addressValue: '',
                addressHouse: '',
                searchingHints: '',
                loaderFlag: false,
                hideHints: false,
                splittedAddressView: false,
                addressValid: false,
                debouncedTimer: null,
                addressPosition: null,
                addressData: {
                    region: '',
                    street: '',
                },
            }
        },
        computed: {
            ...mapGetters(['isChrome']),
            loaderColor() {
                return this.addressValid ? '#ddd' : '#4c447f'
            }
        },
        watch: {
            addressValue(curr) {
                const addressInput = this.$refs.addressInput
                const houseNumberPostfix = this.$refs.houseNumberPostfix

                let fieldWidth = addressInput.clientWidth - (this.loader ? 88 : 68)

                if (fieldWidth < this.getTextWidth(curr)) {
                    this.splittedAddressView = true
                    houseNumberPostfix.innerHTML = ` ... ${this.addressHouse}` ?? ''
                } else {
                    this.splittedAddressView = false
                    houseNumberPostfix.innerHTML = ''
                }
            },
            value(newVal) {
                this.addressValue = newVal
                this.addressValid = /[0-9]$|[0-9][a-zа-я]$/giu.exec(this.addressValue) === null ? false : true
                this.$emit('valid', this.addressValid)
            },
            loaderStatus(newVal) {
                this.loaderChange(newVal)
            },
        },
        mounted() {
            const addressInput = this.$refs.addressInput
            const houseNumberPostfix = this.$refs.houseNumberPostfix

            setTimeout(() => {
                // addressInput.type = 'text';
                // addressInput.removeAttribute('autocomplete');
            })

            this.debouncedTimer = null


            this.resizeObserver = new ResizeObserver(() => {
                let fieldWidth = addressInput.clientWidth - (this.loader ? 88 : 68)

                const width = this.getTextWidth(addressInput.value)

                // console.log(fieldWidth, width)

                if (fieldWidth < width) {
                    this.splittedAddressView = true
                    houseNumberPostfix.innerHTML = ` ... ${this.addressHouse}` ?? ''
                } else {
                    this.splittedAddressView = false
                    houseNumberPostfix.innerHTML = ''
                }
            })
            this.resizeObserver.observe(addressInput)

            this.$watch('hintsDisabled', cur => {
                if (cur === true) {
                    this.removeHints()
                }
            })

            addressInput.focus()
        },
        beforeUnmount() {
            this.resizeObserver.unobserve(this.$refs.addressInput)
        },
        methods: {
            ...mapActions(['addressInput/getAddressHints', 'addressInput/getCoordinates']),

            hideHintsRelease() {
                setTimeout(() => {
                    this.hideHints = true
                }, 200)
            },

            loaderChange(state) {
                if (state) {
                    if (!this.loaderFlag) {
                        this.loaderFlag = true
                        // console.log('loader-on')
                        this.$emit('loader-change', true)
                    }
                } else {
                    if (this.loaderFlag) {
                        this.loaderFlag = false
                        // console.log('loader-off')
                        this.$emit('loader-change', false)
                    }
                }
            },

            getAddressHints(address, debounced) {
                // console.log('getAddressHints', address, debounced)
                address = address.trim()

                if (address.length > 1) {
                    if (!this.debouncedTimer) {
                        this.loaderChange(true)
                        clearTimeout(this.debouncedTimer)

                        this.debouncedTimer = setTimeout(() => {
                            this.getAddressHints(address, true)
                        }, 1200)
                    } else if (debounced) {
                        this.debouncedTimer = null
                        this.addressData.street = address
                        this['addressInput/getAddressHints'](this.addressData)
                            .then(data => {
                                if (data && data.success) {
                                    this.hints = data.res
                                    setTimeout(() => this.$refs.addressInput.focus())
                                } else {
                                    console.log('address is empty')
                                }
                                this.loaderChange(false)
                            })
                            .catch(err => console.log(err))
                            .finally(() => {})
                    } else {
                        this.loaderChange(true)
                        clearTimeout(this.debouncedTimer)

                        this.debouncedTimer = setTimeout(() => {
                            this.getAddressHints(address, true)
                        }, 1200)
                    }
                } else {
                    if (address.length == 0) {
                        this.clearAll()
                    } else {
                        this.removeHints()
                    }

                    this.loaderChange(false)
                    clearTimeout(this.debouncedTimer)
                }
            },

            onInput(e) {
                this.getAddressHints(e.target.value)
            },

            onTouchStart(e) {
                this.positionY = e.changedTouches[0].clientY
            },

            onTouchMove(e) {
                this.scrollList(this.positionY - e.changedTouches[0].clientY)
                this.positionY = e.changedTouches[0].clientY
            },

            releaseInputHouse() {
                this.$refs.addressInput.focus()
                this.addressValue = this.addressValue.replace(/ *,? *$/, '')
                this.addressValue += ', '
                this.getAddressHints(this.addressValue)
            },

            // выбирает адрес в подсказках
            onSelectAddressHint(item) {
                this.selectAddress = true
                this.hints = []

                const region = item.region ?? 'Владивосток'
                const address = `${item.street}, ${item.house}`
                const street = (item.house ? item.house + ', ' : '') + item.street
                this.addressData = { region, address, street }
                this.addressValue = item.street + (item.house ? ', ' + item.house : '')
                this.getCoordinates()
            },

            // при наборе текста включает поиск адреса
            startFindAddress() {
                this.selectAddress = false
            },

            getCoordinates() {
                // console.log('getCoordinates', this.addressData)
                let { address, street, region } = this.addressData
                this.loaderChange(true)

                this['addressInput/getCoordinates']({ street, region })
                    .then(res => {
                        console.log('res', res)
                        if (res.success) {

                            this.addressPosition = {
                                lat: res.lat,
                                lon: res.lon,
                            }

                            const data = {
                                address,
                                // address: res.address.road,
                                house: res.address.house_number ?? null,
                                position: this.addressPosition,
                            }
                            this.$emit('select', data)

                            if (res.address.house_number) {
                                this.addressHouse = res.address.house_number
                                this.addressValid = true
                                this.$emit('valid', true)
                                this.loaderChange(false)
                            } else {
                                this.releaseInputHouse()
                            }
                        } else {
                            console.log('failure res', res)
                            this.loaderChange(false)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.loaderChange(false)
                    })
            },

            removeHints() {
                this.hints = []
            },

            clearAll() {
                this.removeHints()
                this.addressValue = ''
                this.addressValid = false
                this.$emit('valid', false)
                this.$emit('clear')
                this.addressData.region = null
                this.addressData.address = ''
                const data = {
                    address: '',
                    house: null,
                    position: null,
                }
                this.$emit('select', data)
                this.$refs.addressInput.focus()
            },

            getTextWidth(inputText) {
                let text = document.createElement('span')
                document.body.appendChild(text)

                text.style.font = 'Rubik'
                text.style.fontSize = 16 + 'px'
                text.style.height = 'auto'
                text.style.width = 'auto'
                text.style.position = 'absolute'
                text.style.whiteSpace = 'no-wrap'
                text.innerHTML = inputText

                let width = Math.ceil(text.clientWidth)
                document.body.removeChild(text)

                return width
            },
        },
    }
</script>

<style scoped>
    .hints__wrapper {
        flex: 1 0 425px;
        font-family: Rubik, Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        color: #0c043f;
    }
    @media (max-width: 992px) {
        .hints__wrapper {
            flex: 0 1 100%;
        }
    }
    .hints-address__street {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: inherit;
        opacity: 1;
        height: 24px;
    }

    .hints-address__region {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: inherit;
        opacity: 0.5;
    }

    .address-hint__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span.clear-icon {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;
    }
    .fixed-clear-icon {
        background: none !important;
    }

    span.clear-icon span {
        position: absolute;
        z-index: 10000;
        right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #f6f6f6;
        line-height: 1em;
        cursor: pointer;
    }
    span.delete-icon span:hover {
        background-color: #e0e0e0;
    }

    .input-with-hints-wrapper,
    input {
        width: 100%;
        font-family: Rubik, Arial, Helvetica, sans-serif !important;
        font-style: normal !important;
        line-height: 150% !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        outline: none;
        max-width: 450px;
    }
    .hints-list {
        padding: 0;
        max-height: 300px;
        overflow-y: auto;
        text-align: left;
    }
    .hints {
        list-style-type: none;
        border-bottom: 1px solid rgba(0, 14, 75, 0.05);
    }
    .hints-list :last-child {
        border-bottom: none;
    }
    .hints-list > * {
        height: 50px;
        /* padding: 0 5px; */
        vertical-align: middle !important;
        padding: 0 16px;
    }
    .hints-list > li:hover {
        cursor: pointer;
        background: #7a2a9a;
        /* border-radius: 10px; */
        color: white;
    }
    .hints-input-modal-mask {
        position: absolute;
        z-index: 9999;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid rgba(0, 14, 75, 0.05);
        margin-left: 10px;
        margin-top: 3px;
    }
    .input-delivery-modal {
        padding: 15px 48px 12px 15px;
        height: 48px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(3, 30, 77, 0.1);
        box-sizing: border-box;
        border-radius: 10px;
        width: 100%;
        color: black !important;
    }
    .input-delivery-modal__searching {
        padding-left: 32px !important;
    }

    input.input-delivery-modal:focus {
        animation: shadowAnimation 2s infinite cubic-bezier(0.4, 0, 1, 1);
        animation-direction: alternate;
    }

    .input-delivery-modal::placeholder {
        color: #031e4d6e !important;
    }
    .style-input_error {
        animation: opacityAnimation 2s infinite cubic-bezier(0.4, 0, 1, 1);
        border: none !important;
        background: rgba(245, 44, 44, 0.08) !important;
        animation-direction: alternate-reverse;
    }
    .style-input_error::placeholder {
        color: #0c043f !important;
    }

    .house-number-postfix {
        padding-right: 10px;
        line-height: 150%;
        color: white;
        background: #7a2a9a;
        cursor: default;
    }

    .fixed-address-postfix {
        white-space: nowrap;
    }

    .address-from-map__wrapper {
        flex: 0 0 450px;
        padding: 0;
        min-height: 48px;
    }
    @media screen and (max-width: 992px) {
        .address-from-map__wrapper {
            flex: 1 0 100%;
        }
    }

    .address-from-map {
        display: flex;
        align-items: center;
        padding-left: 30px !important;
        padding-right: 40px;
        height: 48px;
        border-radius: 10px;
        background: #7a2a9a;
        border: 1px solid rgba(3, 30, 77, 0.1);
        color: #fff;
    }
    @media screen and (max-width: 992px) {
        .address-from-map__wrapper {
            flex: 1 0 100%;
        }
    }

    .address-from-map__text {
        display: block;
        max-width: 370px;
        overflow: hidden;
        text-overflow: '';
        white-space: nowrap;
        cursor: default;
    }
    @media screen and (max-width: 992px) {
        .address-from-map__text {
            max-width: 93vw;
        }
    }
    @media screen and (max-width: 700px) {
        .address-from-map__text {
            max-width: 90vw;
        }
    }
    @media screen and (max-width: 550px) {
        .address-from-map__text {
            max-width: 85vw;
        }
    }
    @media screen and (max-width: 440px) {
        .address-from-map__text {
            max-width: 80vw;
        }
    }
    @media screen and (max-width: 440px) {
        .address-from-map__text {
            max-width: 75vw;
        }
    }

    .address-from-map__input {
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: #7a2a9a;
        color: #fff;
        border: none;
        flex-grow: 1;
        margin-top: 3px;
    }

    .fixed-address__container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        margin: 0;
        /* display: none; */
    }
    @media screen and (max-width: 992px) {
        .fixed-address__container {
            width: 100%;
            margin: 0;
        }
    }

    @keyframes opacityAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.8;
        }
        100% {
            opacity: 0.4;
        }
    }
    @keyframes shadowAnimation {
        0% {
            box-shadow: 0 0 0 0.05rem rgb(98, 98, 98);
        }
        50% {
            box-shadow: 0 0 0 0.05rem rgba(98, 98, 98, 0.82);
        }
        100% {
            box-shadow: 0 0 0 0.05rem rgba(98, 98, 98, 0.211);
        }
    }

    /* =======================================INPUT-LOADER======================================= */

    .hints-waiting-loader {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: -4px;
        width: 20px;
        height: 20px;
        z-index: 9999;
    }

    .hints-waiting-loader > div {
        transform-origin: 20px 20px;
        border-radius: 5px;
    }

    .hints-waiting-loader > div:after {
        animation: run-spinner 0.8s linear infinite;
    }

    .hints-waiting-loader > div:after {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 6px;
        top: 8px;
        left: 18px;
        background: var(--loaderColor);
        border-radius: 5px;
        opacity: 0;
    }

    .hints-waiting-loader > div:before {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 6px;
        top: 8px;
        left: 18px;
        border-radius: 5px;
    }

    .hints-waiting-loader > *:nth-child(1) {
        transform: rotate(0deg);
    }
    .hints-waiting-loader > *:nth-child(1):after {
        animation-delay: 0s;
    }

    .hints-waiting-loader > *:nth-child(2) {
        transform: rotate(45deg);
    }
    .hints-waiting-loader > *:nth-child(2):after {
        animation-delay: 0.1s;
    }

    .hints-waiting-loader > *:nth-child(3) {
        transform: rotate(90deg);
    }
    .hints-waiting-loader > *:nth-child(3):after {
        animation-delay: 0.2s;
    }

    .hints-waiting-loader > *:nth-child(4) {
        transform: rotate(135deg);
    }
    .hints-waiting-loader > *:nth-child(4):after {
        animation-delay: 0.3s;
    }

    .hints-waiting-loader > *:nth-child(5) {
        transform: rotate(180deg);
    }
    .hints-waiting-loader > *:nth-child(5):after {
        animation-delay: 0.4s;
    }

    .hints-waiting-loader > *:nth-child(6) {
        transform: rotate(225deg);
    }
    .hints-waiting-loader > *:nth-child(6):after {
        animation-delay: 0.5s;
    }

    .hints-waiting-loader > *:nth-child(7) {
        transform: rotate(270deg);
    }
    .hints-waiting-loader > *:nth-child(7):after {
        animation-delay: 0.6s;
    }

    .hints-waiting-loader > *:nth-child(8) {
        transform: rotate(315deg);
    }
    .hints-waiting-loader > *:nth-child(8):after {
        animation-delay: 0.7s;
    }

    @keyframes run-spinner {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>
