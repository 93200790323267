<template>
    <v-card>
        <v-card-title>
            <v-text-field single-line clearable v-model="search" append-icon="mdi-magnify" :label="$t('common.search')"></v-text-field>
        </v-card-title>

        <v-data-table
            dense
            :items="orders"
            :headers="header"
            :expanded="expanded"
            fixed-header
            height="400px"
            :footer-props="{
                pageText: '{0} - {1} из {2}',
                itemsPerPageText: 'Показывать на странице',
                itemsPerPageAllText: 'все',
                showFirstLastPage: true,
                itemsPerPageOptions: [25, 50, 100, -1],
            }"
            :search="search"
            sort-by="createdAt"
            @click:row="onClickRow"
        >
            <template v-slot:[`item.name`]="{ item }">
                <span class="text-caption">
                    <b>{{ item.name }}</b>
                </span>
            </template>

            <template v-slot:[`item.address`]="{ item }">
                <td class="tooltip-text">
                    <span>{{ item.address?.substr(0, 24) ?? '--' }}</span>
                </td>
            </template>

            <template v-slot:[`item.courierName`]="{ item }">
                <b>{{ item.courierName.length > 20 ? item.courierName.substr(0, 20) + '...' : item.courierName }}</b>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
                <span>
                    {{ getOrderMode(item, true) }} 
                    {{ timeToString(item.createdAt) }}
                </span>
            </template>

            <template v-slot:[`item.dateReady`]="{ item }">
                {{ timeToString(item.dateReady) }}
            </template>

            <template v-slot:[`item.dateDelivery`]="{ item }">
                {{ timeToString(item.dateDelivery) }}
            </template>

            <template v-slot:[`item.dateTake`]="{ item }">
                <span class="mr-2">{{ timeToString(item.dateTake) }}</span>
                <v-chip v-if="item.dateTake" class="px-1 mb-1" :color="timeColorHtml(item.dateTake, item.dateReady)" x-small label text-color="white">
                    {{ timeDiff(item.dateTake, item.dateReady) }}
                </v-chip>
            </template>

            <template v-slot:[`item.dateComplete`]="{ item }">
                <span class="mr-2">{{ timeToString(item.dateComplete) }}</span>
                <v-chip v-if="item.dateComplete" class="px-1 mb-1" :color="timeColorHtml(item.dateComplete, item.dateDelivery)" x-small label text-color="white">
                    {{ timeDiff(item.dateComplete, item.dateDelivery) }}
                </v-chip>
            </template>
            <template v-slot:[`item.source`]="{ item }">
                <td>
                    <v-avatar size="24">
                        <img alt="source" :src="iconSource1(item)" />
                    </v-avatar>
                </td>
            </template>
            <!-- добавление выпадающего меню для кнопок восстановления -->
            <template v-if="!userIsPoint" v-slot:[`expanded-item`]="{ headers }">
                <td :colspan="headers.length">
                    <v-row class="ma-4 d-flex justify-space-around">
                        <v-col class="d-flex justify-center" xs="12" sm="3" offset-md="1" offset-lg="3">
                            <v-btn :disabled="disBtn" @click="restoreOrder()" color="error">восстановить заказ</v-btn>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        props: {
            orders: {
                type: Array,
                default() {
                    return {}
                },
            },
        },
        mixins: [Status, Orders],
        data() {
            return {
                //отображает выпадающее меню в удаленных заказах
                expanded: [],
                search: '',
                disBtn: false,
                selectedOrder: null,
            }
        },
        created() {
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
        },

        computed: {
            header() {
                return [
                    { text: 'Сист.', value: 'source', sortable: false },
                    { text: 'Название.', value: 'name', sortable: false },
                    { text: 'Гость', value: 'customerString', sortable: false },
                    { text: 'Адрес доставки', value: 'address', sortable: false },
                    { text: 'Курьер', value: 'courierName', sortable: false },
                    { text: 'Готов', value: 'createdAt', sortable: false },
                    { text: 'Забрать', value: 'dateReady', sortable: false },
                    { text: 'Доставить', value: 'dateDelivery', sortable: false },
                    { text: 'Забран', value: 'dateTake', sortable: false },
                    { text: 'Доставлен', value: 'dateComplete', sortable: false },
                ]
            },
        },
        methods: {
            // активирует выпадающее меню для выбранного заказа с кнопками восстановления
            onClickRow(order) {
                this.disBtn = false
                this.expanded = order === this.expanded[0] ? [] : [order]

                if (!order) {
                    console.error('NOT FOUND ORDER', order)
                    return
                }
                this.selectedOrder = order

                // запрет на восстановление
                if (
                    order.createdAt === null ||
                    order.createdAt.length <= 0 ||
                    order.dateReady === null ||
                    order.dateReady.length <= 0 ||
                    order.dateDelivery === null ||
                    order.dateDelivery.length <= 0
                ) {
                    this.disBtn = true
                    return
                }
            },

            restoreOrder() {
                const data = { orderId: this.selectedOrder.id }

                // if (this.selectedOrder.isCombined) {
                //     this.$store
                //         .dispatch('restore_multi_order', data)
                //         .then(res => {
                //             if (res.data.success) {
                //                 console.log("restore_multi_order ok");
                //                 const data = {
                //                     status: true,
                //                     name: this.selectedOrder.name,
                //                 }
                //                 return this.$emit('successRestore', data)
                //             }
                //         })
                //         .catch(err => {
                //             console.log('restore_multi_order error', err.name, err.message)
                //             const data = {
                //                 status: false,
                //                 id: this.selectedOrder.id,
                //             }
                //             this.$emit('successRestore', data)
                //         })
                // } else {
                    this.$store
                        .dispatch('order_restore', data)
                        .then(res => {
                            if (res.data.success) {
                                const data = {
                                    status: true,
                                    name: this.selectedOrder.name,
                                }
                                return this.$emit('successRestore', data)
                            }
                        })
                        .catch(err => {
                            console.log('order restore error', err.name, err.message)
                            const data = {
                                status: false,
                                id: this.selectedOrder.id,
                            }
                            this.$emit('successRestore', data)
                        })
                // }
            },
        },
    }
</script>

<style lang="stylus">
    .v-data-table {
      padding: 0 16px;

      .v-data-table__wrapper > table > thead > tr {
        th {
          white-space: nowrap;
          padding: 0 8px;
        }
      }

      .v-data-table__wrapper > table > tbody > tr {
        cursor: pointer;

        td {
          white-space: nowrap;
          padding: 0 8px;
          font-size: 0.75 rem;

          p {
            margin-top: 6px;
            margin-bottom: 6px;
            height: 22px;
          }

          td {
            padding-left: 0;
          }
        }
      }
    }
</style>
