<template>
    <div class="container">
        <modal :width="innerWidth >= 768 ? '750px' : '100%'" :height="innerWidth >= 768 ? '577px' : '100%'" @close-modal="onCloseModal()">
            <template #header>
                <div :class="{ _containerDA: innerWidth < 992 }">
                    <h3 class="delivery-header__wrapper">{{ header }}</h3>
                </div>
            </template>

            <template #body>
                <div class="delivery-body__container">
                    <div :class="{ 'delivery-body__content': true, _container: innerWidth < 992 }">
                        <p class="map-subtitle">{{ subtitle }}</p>

                        <div class="delivery-input-form__container">

                            <hints-input
                                :error-address="''"
                                :clear-hints="false"
                                :clearable="true"
                                :loader="true"
                                :value="addressValue"
                                :loader-status="showLoader"
                                placeholder="Начните вводить адрес или укажите на карте"
                                @loader-change="onLoaderChange"
                                @select="onAddressSelect"
                                @valid="onAddressValid"
                            />

                            <div v-if="innerWidth >= 992" class="btn-define-geo__wrapper">
                                <button class="btn-define-geo" :disabled="showLoader || !(validAddress || accessPosition)" @click="onClickSelect">
                                    <div v-if="showLoader && selfPosition">
                                        <div class="dot-pulse" />
                                        <div class="dot-pulse" />
                                        <div class="dot-pulse" />
                                    </div>
                                    <div v-else>
                                        <span class="define-geo-text">{{ buttonTextSelect }}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div :class="{ 'delivery-body__map': true, _container: innerWidth < 992 }">
                        <div class="delivery-map__wrapper">
                            <div id="address-input-map" :style="innerWidth >= 992 ? 'min-height: 364px;' : ''">
                                <div class="tools-panel">
                                    <div v-if="selfPosition" class="icon-round-bg" @click="onUserLocation" @mousedown="$event.stopPropagation()" @mouseup="$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                            <path
                                                d="M2.78993 0.27874C1.94998 -0.282376 0.726335 0.321467 0.817293 1.41362L0.843822 1.73215C1.24856 6.59192 2.10458 11.4064 3.40145 16.1173C3.71031 17.2392 5.26258 17.3359 5.73384 16.296L7.68507 11.9904C7.87161 11.5788 8.32454 11.3146 8.81605 11.3673L13.6435 11.8842C14.7516 12.0029 15.5347 10.65 14.6467 9.79265C11.082 6.35127 7.20461 3.22789 3.06164 0.460249L2.78993 0.27874Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <div class="icon-round-bg" @click="onZoomOut" @mousedown="$event.stopPropagation()" @mouseup="$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M1.949 10.6991C4.20105 12.9512 7.75719 13.1043 10.1866 11.1586L14.9126 15.8846C15.1811 16.153 15.6164 16.153 15.8849 15.8846C16.1534 15.6161 16.1534 15.1808 15.8849 14.9123L11.1589 10.1863C13.1046 7.75688 12.9515 4.20074 10.6994 1.94869C8.28307 -0.467678 4.36536 -0.467679 1.949 1.94869C-0.467373 4.36506 -0.467373 8.28277 1.949 10.6991ZM4.05862 5.66249C3.67893 5.66249 3.37112 5.97029 3.37112 6.34999C3.37112 6.72968 3.67893 7.03749 4.05862 7.03749H8.64196C9.02165 7.03749 9.32946 6.72968 9.32946 6.34999C9.32946 5.97029 9.02165 5.66249 8.64196 5.66249H4.05862Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <div class="icon-round-bg" @click="onZoomIn" @mousedown="$event.stopPropagation()" @mouseup="$event.stopPropagation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10.1866 11.1586C7.75719 13.1043 4.20105 12.9512 1.949 10.6991C-0.467373 8.28277 -0.467373 4.36506 1.949 1.94869C4.36536 -0.467679 8.28307 -0.467678 10.6994 1.94869C12.9515 4.20074 13.1046 7.75688 11.1589 10.1863L15.8849 14.9123C16.1534 15.1808 16.1534 15.6161 15.8849 15.8846C15.6164 16.153 15.1811 16.153 14.9126 15.8846L10.1866 11.1586ZM3.37112 6.34999C3.37112 5.97029 3.67893 5.66249 4.05862 5.66249H5.66279V4.05832C5.66279 3.67862 5.97059 3.37082 6.35029 3.37082C6.72999 3.37082 7.03779 3.67862 7.03779 4.05832V5.66249H8.64196C9.02165 5.66249 9.32946 5.97029 9.32946 6.34999C9.32946 6.72968 9.02165 7.03749 8.64196 7.03749H7.03779V8.64165C7.03779 9.02135 6.72999 9.32915 6.35029 9.32915C5.97059 9.32915 5.66279 9.02135 5.66279 8.64165V7.03749H4.05862C3.67893 7.03749 3.37112 6.72968 3.37112 6.34999Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="map-pin" role="img" viewBox="0 0 384 512">
                                    <path
                                        fill="#7a2a9a"
                                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <modal v-if="showWarningModal" :width="innerWidth >= 600 ? '490px' : '80%'" height="390px" :z-index="999999" @close-modal="onCloseWarningModal()">
                        <template #header>
                            <h4 class="delivery-header__wrapper notification-geo-header__container _notification__container">Геопозиция</h4>
                        </template>
                        <template #body>
                            <div class="notification-geo-body__container">
                                <section class="notification-geo-warning__container _notification__container">
                                    <div class="notification-geo-warning__wrapper">
                                        <div class="notification-geo-warning__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                                                <path
                                                    d="M2.84341 2.55967C4.45908 0.946725 6.64362 0.0328681 8.92653 0.0149179C11.2094 -0.00303226 13.4081 0.87636 15.0489 2.4637C16.6897 4.05104 17.6415 6.21933 17.6992 8.50158C17.7569 10.7838 16.9159 12.9974 15.3574 14.6657L15.1574 14.8727C14.2424 15.7877 12.7124 17.2827 10.5644 19.3587C10.1448 19.7644 9.58404 19.9911 9.00041 19.9911C8.41678 19.9911 7.85598 19.7644 7.43641 19.3587L3.58641 15.6097C3.30241 15.3297 3.05441 15.0837 2.84341 14.8727C2.03483 14.0641 1.39342 13.1043 0.95581 12.0478C0.518203 10.9914 0.292969 9.85914 0.292969 8.71567C0.292969 7.5722 0.518203 6.43992 0.95581 5.3835C1.39342 4.32708 2.03483 3.3672 2.84341 2.55867V2.55967ZM12.0304 5.68567L11.9464 5.61267C11.819 5.51848 11.665 5.46707 11.5065 5.46581C11.3481 5.46455 11.1933 5.51351 11.0644 5.60567L10.9704 5.68567L9.00041 7.65567L7.03041 5.68567L6.94641 5.61267C6.81899 5.51848 6.665 5.46707 6.50655 5.46581C6.3481 5.46455 6.19332 5.51351 6.06441 5.60567L5.97041 5.68567L5.89741 5.76967C5.80322 5.8971 5.75181 6.05108 5.75055 6.20953C5.74929 6.36799 5.79825 6.52277 5.89041 6.65167L5.97041 6.74567L7.93941 8.71567L5.96941 10.6857L5.89741 10.7697C5.80299 10.8972 5.75144 11.0514 5.75019 11.21C5.74893 11.3687 5.79802 11.5237 5.89041 11.6527L5.97041 11.7457L6.05441 11.8187C6.18184 11.9129 6.33582 11.9643 6.49427 11.9655C6.65273 11.9668 6.80751 11.9178 6.93641 11.8257L7.03041 11.7457L9.00041 9.77667L10.9704 11.7467L11.0544 11.8187C11.1818 11.9129 11.3358 11.9643 11.4943 11.9655C11.6527 11.9668 11.8075 11.9178 11.9364 11.8257L12.0304 11.7457L12.1034 11.6617C12.1976 11.5342 12.249 11.3803 12.2503 11.2218C12.2515 11.0634 12.2026 10.9086 12.1104 10.7797L12.0304 10.6857L10.0614 8.71567L12.0314 6.74567L12.1034 6.66167C12.1976 6.53424 12.249 6.38026 12.2503 6.22181C12.2515 6.06335 12.2026 5.90857 12.1104 5.77967L12.0304 5.68567L11.9464 5.61267L12.0304 5.68567Z"
                                                    fill="#F52C2C"
                                                />
                                            </svg>
                                        </div>
                                        <div class="notification-geo-warning__text">Доступ к геопозиции запрещен</div>
                                    </div>
                                </section>
                                <section class="notification-geo-info__container _notification__container">
                                    <p class="notification-geo-info__text">Для определения вашего местоположения разрешите использование геопозиции в настройках вашего браузера</p>
                                </section>
                            </div>
                        </template>
                        <template #footer>
                            <div class="footer-btn__wrapper">
                                <button class="btn btn-block btn-delivery" @click="onCloseWarningModal()">ОК</button>
                            </div>
                        </template>
                    </modal>
                </div>
            </template>

            <template #footer>
                <div v-if="innerWidth < 992 && (validAddress || accessPosition)" class="footer-btn__wrapper">
                    <button class="btn btn-block btn-delivery" @click="onClickSelect">{{ buttonTextSelect }}</button>
                </div>
                <div v-else />
            </template>
        </modal>
    </div>
</template>

<script>
    import Modal from './Modal.vue'
    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'
    import hintsInput from './InputWithHints.vue'
    import { mapGetters } from 'vuex'
    const config = require('../../config')

    export default {
        name: 'AddressInput',
        components: { Modal, hintsInput },
        props: {
            data: {
                type: Object,
                default: () => {},
            },

            center: {
                type: Array,
                default: () => [43.114894, 131.885891],
            },

            zoom: {
                type: Number,
                default: () => 14,
            },

            header: {
                type: String,
                default: () => 'Укажите адрес',
            },

            subtitle: {
                type: String,
                default: () => 'Поможем найти адрес на карте',
            },

            accessPosition: {
                type: Boolean,
                default: false,
            },

            selfPosition: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['input', 'select', 'close'],
        data() {
            return {
                map: null,
                showWarningModal: false,
                showLoader: false,
                rememberedAddress: {},
                addressValue: '',
                fixedCoord: [],
                forceQuit: true,
                searchingHints: false,
                mapDragFlag: false,
                mapDownFlag: false,
                mapZoomFlag: false,
                mapWheelFlag: false,
                manualZoomFlag: false,
                debouncedTimer: false,
                addressHouse: '',
                addressData: {},
                validAddress: false,
            }
        },
        computed: {
            ...mapGetters({
                isChrome: 'isChrome',
            }),
            innerWidth() {
                return window.innerWidth
            },
            buttonTextSelect() {
                const addressDiscard = this.accessPosition ? 'Выбрать координаты' : 'Не доступно'
                return this.validAddress ? 'Выбрать адрес' : addressDiscard
            }
        },
        watch: {

        },
        mounted() {
            // data init
            if (this.data) {
                this.addressData = {
                    address: this.data.address,
                    position: this.data.position,
                }
            }

            this.mapCreate()

            if (this.addressData) {
                this.addressValue = this.addressData.address
                if (this.addressData.address) {
                    // поиск по строке адреса
                    console.error('find by address string not implemented!')
                }
                if (this.addressData.position && this.addressData.position.lat && this.addressData.position.lng) {
                    // поиск по координатам
                    this.getAddress(this.addressData.position)
                    this.map.setView(L.latLng(this.addressData.position))
                }
            }
        },
        beforeUnmount() {
            this.map.off('zoomend')
            this.map.off('dragstart')
            this.map.off('dragend')
            this.map.off('mouseup')
            this.map.off('click')

            const mapElem = L.DomUtil.get('address-input-map')
            L.DomEvent.off(mapElem, 'wheel', () => {
                this.mapWheelFlag = true
            })
        },
        methods: {
            onZoomIn() {
                this.map.zoomIn()
                this.manualZoomFlag = true
            },

            onZoomOut() {
                this.map.zoomOut()
                this.manualZoomFlag = true
            },

            onUserLocation() {
                this.getUserLocation()
            },

            clearAddress() {
                // console.log('clearAddress')
                this.addressValue = ''
            },

            async getAddress(coord) {
                if (!this.debouncedTimer) {
                    this.showLoader = true
                    // console.log('getAddress start coord', coord)
                    this.searchingHints = true
                    this.addressData.position = coord
                    // console.log('this.addressData.position', this.addressData.position)

                        this.debouncedTimer = setTimeout(() => {
                            this.debouncedTimer = null
                        }, 500)

                    return this.$store.dispatch('addressInput/getAddress', coord).then(res => {
                        if (!res?.success) return { success: false, msg: 'Неизвестный адрес' }

                        // this.addressValue = res.address.street
                        this.addressHouse = res.address.house
                        this.searchingHints = false
                        // console.log('found address', res.address)

                        this.showLoader = false

                        this.fixHint(res.address)

                        return res.address
                    })
                } else {
                    this.clearAddress()
                    // console.log('getAddress debounced')
                    clearTimeout(this.debouncedTimer)
                    this.debouncedTimer = setTimeout(() => {
                        this.debouncedTimer = null
                    }, 500)
                }
            },

            fixHint(address) {
                this.addressValue = address.street
                if (address.house) {
                    this.addressHouse = address.house
                }
            },

            mapCreate() {
                let center = this.center
                let zoom = this.zoom
                if (!this.center || Number.isNaN(this.center[0]) || Number.isNaN(this.center[1])) {
                    center = [43.121284, 131.901917]
                }

                if (this.addressData.position) {
                    center = this.addressData.position
                    zoom = 17
                }

                const map = new L.map('address-input-map', {
                    center,
                    zoom,
                    zoomControl: false,
                    doubleClickZoom: false,
                    attributionControl: false,
                })

                L.tileLayer(config.map.url, {
                    attributionControl: false,
                    maxZoom: 20,
                    minZoom: 10,
                    retina: '@2x',
                    detectRetina: true,
                }).addTo(map)

                const addressRelease = event => {
                    let position = this.map.getCenter()
                    if (!this.mapDragFlag) {
                        position = event.latlng
                        this.map.setView(L.latLng(position))
                    }
                    this.getAddress(position)
                }

                map.on('zoomend', () => {
                    if (!this.manualZoomFlag && this.mapWheelFlag) {
                        this.clearAddress()
                    }
                    this.manualZoomFlag = false
                    this.mapWheelFlag = false
                })

                map.on('dragstart', () => {
                    this.mapDragFlag = true
                })

                map.on('dragend', e => {
                    addressRelease(e)
                    this.mapDragFlag = false
                })

                map.on('mouseup', e => {
                    // console.log('mouseup', e)
                    if (!this.mapDragFlag) {
                        addressRelease(e)
                    }
                })

                const mapElem = L.DomUtil.get('address-input-map')
                L.DomEvent.on(mapElem, 'wheel', () => {
                    this.mapWheelFlag = true
                })

                this.map = map
            },
            
            onLoaderChange(state) {
                this.showLoader = state
            },

            onAddressSelect(data) {
                // console.log('onAddressSelect', data)
                if (data) {
                    const { address, position } = data
                    this.addressValue = address
                    if (position && position.lat && position.lon) {
                        this.addressData.position = L.latLng(position.lat, position.lon)
                        this.map.setView(this.addressData.position, 17)
                    }
                }
            },

            onAddressValid(valid) {
                // console.log('onAddressValid', valid)
                this.validAddress = valid
            },

            async getUserLocation() {
                async function getPosition() {
                    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true }))
                }

                if (this.selfPosition) {
                    this.showLoader = true

                    this.clearAddress()

                    getPosition()
                        .then(async position => {
                            let lat = position.coords.latitude
                            let lng = position.coords.longitude

                            if (!lat || !lng) return

                            this.addressData.position = { lat, lng }

                            this.map.setView(this.addressData.position, 17)

                            this.showLoader = false

                            this.getAddress(this.addressData.position)
                        })
                        .catch(err => {
                            this.showWarningModal = true
                            this.showLoader = false
                            console.log('D_LOG pos undefined', err)
                        })
                }
            },

            onClickSelect() {
                // console.log('onClickSelect', this.addressValue)
                this.forceQuit = false

                this.addressData.position = this.map.getCenter()
                
                this.$emit('select', {
                    address: this.addressValue,
                    position: this.addressData.position,
                })
                this.$emit('close')
            },

            onCloseModal() {
                this.forceQuit = false
                this.$emit('close')
            },

            onInput(event) {
                // console.log('onInput', event.target.value)
                this.$emit('input', event.target.value)
            },

            onCloseWarningModal() {
                this.showWarningModal = false
            },
        },
    }
</script>

<style scoped>
    /* =======================================WARNING-MODAL======================================= */

    .notification-geo-header__container {
        font-style: normal;
        font-weight: 700;
        font-size: 28px !important;
        line-height: 130%;
        letter-spacing: 1px;
    }
    .notification-geo-body__container {
        display: flex;
        flex-direction: column;
    }
    .notification-geo-body__container > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .notification-geo-warning__wrapper {
        display: inline-flex;
        align-items: center;
        height: 32px;
        background-color: rgba(255, 0, 0, 0.1);
        border-radius: 10px;
        padding: 0 10px;
    }
    .notification-geo-warning__wrapper > * {
        padding: 0 4px;
    }
    .notification-geo-warning__icon {
        height: 20px;
        margin-top: -6px;
    }
    .notification-geo-warning__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
    }
    /* =======================================MAP======================================== */

    .map-pin {
        width: 30px !important;
        height: 30px !important;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 30px;
        right: 0;
        z-index: 9998;
    }

    .tools-panel {
        margin: auto;
        position: absolute;
        display: flex;
        right: 30px;
        bottom: 20px;
        z-index: 9998;
    }
    @media (max-width: 992px) {
        .tools-panel {
            flex-direction: column;
            top: 20px;
            right: 10px;
        }
        .icon-round-bg:first-child {
            margin-bottom: 20px !important;
        }
        .icon-round-bg:last-child {
            margin-top: 10px !important;
        }
    }

    .tools-panel > * {
        height: 20px !important;
        width: 20px !important;
        cursor: pointer;
    }

    .btn-zoom-out {
        margin: 0 10px 0 20px;
    }

    .delivery-body__map {
        margin: 0 0 -104px;
        display: initial;
    }
    @media (max-width: 992px) {
        .delivery-body__map {
            height: 100%;
        }
        .delivery-map__wrapper {
            height: 100%;
        }
    }

    #address-input-map {
        border-radius: 0 0 15px 15px;
    }

    .icon-round-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px !important;
        height: 36px !important;
        background-color: #7a2a9a;
        border-radius: 50%;
        margin-right: 10px;
    }

    .icon-round-bg:first-child {
        margin-right: 20px;
    }

    /* =======================================INPUT======================================== */

    .delivery-input-wrapper {
        position: relative;
    }

    .unknown-address {
        background: gray !important;
    }

    .hints {
        list-style-type: none;
    }

    .input-modal-mask {
        position: fixed;
        z-index: 9998;
        width: 100%;
        height: 20px;
        display: table;
        background-color: white;
    }

    .delivery-input-form__container {
        position: relative;
        display: flex;
        margin: 0 16px;
    }

    .btn-define-geo__wrapper {
        flex: 0 0 255px;
        padding: 0 5px;
        min-height: 100%;
    }

    .btn-define-geo {
        background: linear-gradient(49.06deg, #f9c801 20.38%, #fff854 135.95%);
        border-radius: 10px;
        height: 100%;
        outline: none;
        border: none;
        padding: 12px 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .btn-define-geo:hover {
        background: linear-gradient(49.06deg, #eebe07 20.38%, #fcf41d 135.95%);
    }

    .btn-define-geo:disabled {
        background: #eaebee;
        cursor: default;
    }

    .define-geo-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 133%;
        display: flex;
        align-items: center;
        color: #0c043f;
    }

    .btn-define-geo:disabled .define-geo-text {
        color: #7c7b85;
    }

    /* =======================================OTHER======================================== */

    .delivery-header__wrapper {
        margin: 0;
        font-size: 1.5rem !important;
    }
    @media (max-width: 460px) {
        .delivery-header__wrapper {
            font-size: 1.2rem !important;
        }
    }
    @media (max-width: 374px) {
        .delivery-header__wrapper {
            font-size: 1rem !important;
        }
    }

    .map-subtitle {
        margin-bottom: 25px !important;
    }

    p {
        font-family: Rubik Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        color: #0c043f;
        opacity: 0.7;
    }

    .modal-body {
        padding: 0 !important;
    }

    .delivery-body__content {
        margin-bottom: 20px;
    }

    .footer-btn__wrapper {
        position: relative;
        width: 100%;
        text-align: center;
    }
    @media (max-width: 992px) {
        .footer-btn__wrapper {
            z-index: 9998;
        }
    }

    .modal-footer .btn-delivery:disabled {
        background: #eaebee;
        color: rgb(12, 4, 63, 0.5);
    }

    ._containerDA {
        padding: 0 20px !important;
    }

    @media (max-width: 992px) {
        .address-from-map {
            width: 100% !important;
        }
        .map-wrapper {
            top: 0 !important;
        }
        .delivery-info-wrapper {
            background: white;
            position: relative;
            z-index: 100000;
            margin: -16px -16px 0 -16px;
            padding: 15px 16px;
        }
        .hints-div {
            margin-bottom: 0px !important;
        }
        .map-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
        }
        #address-input-map {
            height: 100%;
            margin: 0 !important;
        }
        .modal-body {
            height: 100% !important;
        }
        .define-geo-text {
            display: none;
        }
    }
    .btn-delivery {
        background: linear-gradient(49.06deg, #f9c801 20.38%, #fff854 135.95%);
        border-radius: 10px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        align-items: center !important;
        color: #0c043f !important;
        padding: 10px 0 !important;
        position: relative;
        z-index: 9999;
        min-width: 240px;
    }

    /* =======================================MOBILE-MAP======================================= */

    /* .delivery-body__container {
        height: 100%;
        position: absolute;
    } */

    @media (max-width: 992px) and (min-height: 1050px) {
        .delivery-body__container {
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 6fr;
        }
    }
    @media (max-width: 992px) and (min-height: 890px) {
        .delivery-body__container {
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 5fr;
        }
    }
    @media (max-width: 992px) and (min-height: 750px) {
        .delivery-body__container {
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 4fr;
        }
    }
    @media (max-width: 992px) and (max-height: 749px) {
        .delivery-body__container {
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 3fr;
        }
    }

    /* =======================================PULSE-LOADER======================================= */

    .dot-pulse {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #0c043f;
        display: inline-block;
        margin: 0 4px;
        animation: scaling 1.5s ease-in-out infinite;
    }
    .dot-pulse:nth-child(0) {
        animation-delay: 0s;
    }
    .dot-pulse:nth-child(1) {
        animation-delay: 0.5s;
    }
    .dot-pulse:nth-child(2) {
        animation-delay: 1s;
    }

    @keyframes scaling {
        0%,
        100% {
            transform: scale(0.3);
        }
        50% {
            transform: scale(1);
        }
    }

</style>
